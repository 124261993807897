#protecting-your-business {
	.section-hero {
		background-color: $color-white;
		overflow: hidden;
		padding-bottom: 135px;
		padding-top: 90px;
		position: relative;
		z-index: 8;

		@include mq(tablet-down) {
			padding-bottom: 50px;
			padding-top: 35px;
		}


		.sub-content {
			@extend %container;

			display: flex;

			@include mq(desktop) {
				margin: 0 auto;
			}


			@include mq(tablet-down) {
				flex-direction: column;
			}


			.sub-hero-content {
				width: 50%;
				z-index: 8;

				@include mq(tablet-down) {
					width: 100%;
				}


				h1 {
					font-size: 40px;
					font-weight: $font-weight-medium;
					line-height: 54px;

					@include mq(tablet-down) {
						font-size: 25px;
						line-height: 60px;
					}
				}

				p {
					font-size: 22px;
					font-weight: $font-weight-book;
					line-height: 36px;
					max-width: 496px;
					width: 100%;

					@include mq(tablet-down) {
						font-size: 15px;
						line-height: 28px;
					}
				}

				.list-button-links {
					@extend %list-default;

					display: flex;
					margin: 50px 0 0 0;

					@include mq(tablet-down) {
						align-items: center;
						flex-direction: column;
						justify-content: center;
						margin: 30px auto 50px auto;
						width: 100%;
					}


					.item-button-link {
						margin: 0 8px 0 0;
						max-width: 175px;
						text-align: center;
						width: 210px;

						@include mq(tablet-down) {
							margin: 0 0 15px 0;
							width: 100%;
						}


						&:last-child {
							margin: 0;
						}

						> a, button {
							font-weight: $font-weight-book;
							justify-content: center;

							@include mq(tablet-down) {
								font-size: 14px;
								max-width: 100%;
								width: 100%;
							}
						}
					}
				}
			}

			.sub-hero-image {
				display: flex;
				justify-content: flex-end;
				position: relative;
				width: 50%;

				@include mq(tablet-down) {
					width: 100%;
				}


				.hero-content-image {
					background-position: center;
					background-repeat: no-repeat;
					background-size: cover;
					border-radius: 25px;
					display: block;
					height: 488px;
					position: relative;
					max-width: 679px;
					width: 100%;

					@include mq(desktop) {
						border-radius: 25px;
					}


					@include mq(tablet-down) {
						height: 398px;
						max-width: unset;
					}


					@include mq(mobile) {
						height: 236px;
						border-radius: 25px;
					}
				}

				.list-hero-icons {
					@extend %list-default;

					display: flex;
					height: 100%;
					left: 0;
					position: absolute;
					top: 0;
					width: 100%;

					@include mq(tablet-down) {
						left: 0;
					}


					> .item-hero-icon {
						align-items: center;
						border-radius: 50%;
						display: flex;
						filter: drop-shadow(15px 15px 29px rgba(0, 0, 0, 0.26));
						justify-content: center;
						position: absolute;

						@include mq(tablet-down) {
							filter: drop-shadow(15px 15px 13px rgba(0, 0, 0, 0.26));
						}


						&.icon-fire {
							background-color: #003E7E;
							bottom: 85px;
							height: 90px;
							left: 0;
							width: 90px;

							@include mq(tablet-down) {
								bottom: -10px;
								height: 85px;
								left: 70%;
								width: 85px;
							}


							@include mq(mobile) {
								height: 45px;
								width: 45px;
							}


							> svg {
								height: 55px;
								position: relative;
								width: 39px;

								@include mq(tablet-down) {
									height: 55px;
									width: 40px;
								}


								@include mq(mobile) {
									height: 22px;
								}
							}
						}

						&.icon-police {
							background-color: #AEAEAE;
							height: 100px;
							left: 68%;
							top: -40px;
							width: 100px;

							@include mq(tablet-down) {
								height: 85px;
								left: 15%;
								top: -22px;
								width: 85px;
							}


							@include mq(mobile) {
								height: 45px;
								left: 8%;
								width: 45px;
							}


							> svg {
								height: 52px;
								position: relative;
								width: 58px;

								@include mq(tablet-down) {
									height: 44.96px;
									width: 39.84px;
								}


								@include mq(mobile) {
									height: 22px;
									width: 25px;
								}
							}
						}

						&.icon-certificate {
							background-color: #003e7e;
							height: 100px;
							left: 81%;
							bottom: -40px;
							width: 100px;

							@include mq(tablet-down) {
								bottom: -22px;
								height: 85px;
								left: 70%;
								top: unset;
								width: 85px;
							}


							@include mq(mobile) {
								height: 48px;
								width: 48px;
							}


							> svg {
								height: 52px;
								position: relative;
								width: 41px;

								@include mq(tablet-down) {
									height: 40px;
									width: 45px;
								}


								@include mq(mobile) {
									height: 25px;
									width: 19px;
								}
							}
						}
					}
				}
			}
		}
	}

	.section-content {
		&--primary {
			@extend %image-content-block, %image-content-block-left;

			background-color: $color-neutral;

			@include mq(tablet-down) {
				padding: 40px 0 80px 0;
			}
		}
	}

	.section-content-banner {
		align-content: center;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		display: flex;
		height: 520px;
		justify-content: center;
		overflow: hidden;
		padding: 143px 0 141px 0;
		position: relative;
		width: 100%;
		z-index: 8;

		@include mq(tablet-down) {
			align-items: center;
			height: 448px;
			padding: unset;
		}


		.content-wrapper {
			color: $color-white;
			text-align: center;
			max-width: 1037px;
			width: 100%;

			@include mq(tablet-down) {
				max-width: unset;
				width: 91%;
			}


			p {
				font-size: 30px;
				font-weight: $font-weight-light;
				letter-spacing: 0.6px;
				line-height: 51px;
				margin: 0;

				> a {
					color: $color-buttons;
				}

				@include mq(tablet-down) {
					font-size: 22px;
					line-height: 36px;
				}


				> strong {
					font-weight: $font-weight-regular;
				}
			}
		}
	}

	.section-more-protecting-your-business {
		@extend %common-three-boxes;

		background-color: $color-neutral;
		border-bottom: 1px solid #eaeaea;
		padding-bottom: 130px;
		padding-top: 95px;

		@include mq(tablet-down) {
			padding-bottom: 63px;
			padding-top: 30px;
		}


		.sub-content {
			h3 {
				@include mq(tablet-down) {
					font-size: 25px;
					line-height: 30px;
					max-width: 80%;
				}
			}
		}
	}
}
