#accreditation {
	.section-hero {
		background-color: $color-background;
		overflow: hidden;
		padding: 130px 0 50px 0;
		position: relative;
		z-index: 8;

		@include mq(tablet-down) {
			padding: 45px 0 30px 0;
		}


		.sub-content {
			@extend %container;

			display: flex;

			@include mq(tablet-down) {
				flex-direction: column;
				margin: auto;
			}


			.sub-hero-content {
				width: 50%;
				z-index: 8;

				@include mq(tablet-down) {
					width: 100%;
				}


				h1 {
					color: $color-white;
					font-size: 40px;
					font-weight: $font-weight-medium;
					line-height: 54px;

					@include mq(tablet-down) {
						font-size: 25px;
						line-height: 60px;
					}
				}

				p {
					color: $color-white;
					font-size: 22px;
					font-weight: $font-weight-book;
					line-height: 36px;
					max-width: 725px;
					width: 100%;

					@include mq(tablet-down) {
						font-size: 15px;
						line-height: 28px;
						max-width: unset;
					}
				}
			}

			.sub-hero-image {
				display: flex;
				justify-content: flex-end;
				width: 50%;

				@include mq(tablet-down) {
					margin-top: 0;
					position: relative;
					width: 100%;
				}


				.hero-content-image {
					background-position: center;
					background-repeat: no-repeat;
					background-size: cover;
					display: block;
					height: 228px;
					position: relative;
					max-width: 559px;
					width: 100%;

					@include mq(tablet-down) {
						border-radius: 25px;
						height: 416px;
						max-width: unset;
						width: 100%;
					}


					@include mq(mobile) {
						height: 236px;
					}
				}
			}
		}
	}

	.section-content {
		background-color: $color-white;
		overflow: hidden;
		padding: 60px 0 85px 0;
		position: relative;
		z-index: 8;

		@include mq(tablet-down) {
			padding: unset;
		}


		.sub-content {
			@extend %container;

			display: flex;
			justify-content: space-between;

			@include mq(tablet-down) {
				flex-direction: column;
				justify-content: unset;
				padding: 30px 0 40px 0;
			}


			.sub-content-wrapper {
				width: 60%;

				@include mq(tablet-down) {
					width: 100%;
				}


				h1 {
					font-size: 30px;
					font-weight: $font-weight-medium;
					line-height: 54px;

					@include mq(tablet-down) {
						font-size: 25px;
						line-height: 60px;
					}
				}

				p {
					font-size: 17px;
					font-weight: $font-weight-book;
					line-height: 30px;
					max-width: 782px;
					width: 100%;

					> a {
						color: $color-buttons;
					}

					@include mq(tablet-down) {
						font-size: 15px;
						line-height: 28px;
					}
				}
			}

			.sub-content-links {
				width: 436px;

				@include mq(tablet-down) {
					margin-top: 30px;
					width: 100%;
				}


				.sub-content-image {
					background-position: center;
					background-repeat: no-repeat;
					background-size: cover;
					border-radius: 25px 25px 0 0;
					height: 269px;

					@include mq(tablet-down) {
						height: 200px;
					}
				}

				.sub-links-wrapper {
					background-color: $color-tertiary;
					border-radius: 0 0 25px 25px;
					padding: 40px 40px 45px 27px;

					@include mq(tablet-down) {
						padding: 20px 20px 30px 20px;
					}


					> a {
						color: $color-white;
						display: block;
						font-size: 22px;
						font-weight: $font-weight-regular;
						line-height: 30px;
						margin-bottom: 30px;

						@include mq(tablet-down) {
							font-size: 17px;
							margin-bottom: 10px;
						}
					}

					ul.list-accreditation-links {
						@extend %list-default;

						display: block;

						li.item-accreditation-link {
							display: flex;
							margin-bottom: 25px;

							@include mq(tablet-down) {
								margin-bottom: 10px;
							}


							&:last-child {
								margin: 0;
							}

							> a {
								display: flex;
								justify-content: space-between;
								width: 100%;

								span {
									color: $color-white;
									display: block;
									font-size: 20px;
									font-weight: $font-weight-light;
									line-height: 23px;
									max-width: 307px;

									@include mq(tablet-down) {
										font-size: 17px;
									}
								}

								> svg {
									align-self: center;
									color: $color-white;
									height: 10.58px;
									width: 15.41px;
								}
							}
						}
					}
				}
			}
		}
	}

	.section-content-accreditation {
		background-color: $color-white;
		border-bottom: 1px solid #eaeaea;
		overflow: hidden;
		padding: 0 0 130px 0;
		position: relative;
		z-index: 8;

		@include mq(tablet-down) {
			padding: 0 0 50px 0;
		}


		.sub-content {
			@extend %container;

			.sub-content-wrapper {
				width: 100%;

				h1 {
					font-size: 30px;
					font-weight: $font-weight-medium;
					line-height: 54px;

					@include mq(tablet-down) {
						font-size: 25px;
						line-height: 60px;
					}
				}

				p {
					font-size: 17px;
					font-weight: $font-weight-book;
					line-height: 30px;
					max-width: 657px;
					width: 100%;

					> a {
						color: $color-buttons;
					}

					@include mq(tablet-down) {
						font-size: 15px;
						line-height: 28px;
					}
				}
			}
		}
	}
}
