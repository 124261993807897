#site-terms {
	.section-main {
		background-color: $color-white;
		overflow: hidden;
		padding-bottom: 135px;
		padding-top: 90px;
		position: relative;
		z-index: 8;

		@include mq(tablet-down) {
			padding-bottom: 20px;
			padding-top: 35px;
		}


		.sub-content {
			@extend %container;

			color: $color-black;

			> h1 {
				font-size: 40px;
				font-weight: $font-weight-semibold;
				line-height: 54px;

				@include mq(tablet-down) {
					font-size: 25px;
					line-height: 60px;
				}
			}

			> p {
				font-size: 17px;
				font-weight: $font-weight-book;
				line-height: 30px;

				@include mq(tablet-down) {
					font-size: 14px;
					line-height: 30px;
				}


				> a {
					color: $color-buttons !important;
				}
			}

			> ul {
				@extend %list-default;

				margin-bottom: 25px;

				li {
					color: $color-black;
					font-size: 17px;
					font-weight: $font-weight-book;
					line-height: 30px;

					@include mq(mobile) {
						font-size: 15px;
					}


					&:before {
						content: '• ';
						color: $color-buttons;
						display: inline-block;
						font-size: 22px;
						margin-right: 20px;
					}

					> a {
						color: $color-buttons !important;
					}
				}
			}
		}
	}
}
