#impartiality {
	.section-hero {
		background: $color-background;
		display: flex;
		flex-direction: column;
		height: 432px;
		justify-content: center;
		position: relative;
		overflow: hidden;
		z-index: 8;

		@include mq(mobile) {
			height: 271px;
		}


		.sub-content {
			@extend %container;

			.sub-copy {
				margin-top: 20px;

				@include mq(mobile) {
					margin-top: 0;
				}


				h1 {
					color: $color-white;
					font-size: 40px;
					font-weight: $font-weight-medium;
					line-height: 54px;

					@include mq(mobile) {
						font-size: 25px;
					}
				}

				p {
					color: $color-white;
					font-size: 22px;
					font-weight: $font-weight-book;
					line-height: 36px;
					max-width: 725px;
					width: 100%;

					@include mq(mobile) {
						font-size: 15px;
						line-height: 28px;
					}
				}
			}
		}
	}

	.section-impartiality-process {
		background-color: $color-white;
		border-bottom: 1px solid #eaeaea;
		padding: 88px 0 105px;
		position: relative;
		z-index: 8;

		@include mq(mobile) {
			padding: 35px 0;
		}


		.sub-content {
			@extend %container;

			align-content: center;
			display: flex;

			@include mq(mobile) {
				flex-direction: column;
			}


			.sub-copy {
				flex: 1;
				margin-right: 95px;
				margin-top: 10px;

				@include mq(mobile) {
					margin-right: 0;
				}


				h2 {
					font-size: 25px;
					font-weight: $font-weight-medium;
					margin-bottom: 40px;

					@include mq(mobile) {
						font-size: $font-size-h3;
					}
				}

				p {
					font-size: 17px;
					font-weight: $font-weight-book;
					line-height: 21px;

					> a {
						color: $color-buttons;
					}

					@include mq(mobile) {
						font-size: 15px;
						line-height: 28px;
					}
				}

				.list-impartiality-processes {
					margin: 0 0 60px 0;

					.item-impartiality-process {
						font-size: 17px;
						font-weight: $font-weight-book;
						line-height: 23px;
						list-style-type: square;
						padding-left: 10px;

						&::marker {
							color: $color-buttons;
						}

						a {
							color: $color-primary;
							font-weight: $font-weight-regular;
						}
					}
				}
			}

			.sub-image {
				background-position: center center;
				background-repeat: no-repeat;
				background-size: cover;
				border-radius: 25px;
				height: 394px;
				max-width: 436px;
				width: 100%;

				@include mq(mobile) {
					height: 320px;
					margin-top: 45px;
					max-width: none;
				}
			}
		}
	}
}
