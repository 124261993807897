#search-suppliers {
	padding-bottom: 0;

	.footer-main {
		position: relative;
	}

	.section-supplier-map {
		a:not(.supplier-map-results-paginator-btn) {
			color: $color-background;
			font-weight: $font-weight-book;
		}
	}

	h2 {
		font-size: $font-size-largest;
		line-height: 1;
	}

	.sub-supplier-map {
		display: flex;

		$supplier-map-form-width-desktop: 475px;
		$supplier-map-form-width-mobile: 100%;

		$mobile-padding: 16px;

		@include mq(tablet-down) {
			flex-direction: column;
		}

		.supplier-map-form {
			padding: 32px 46px;
			width: $supplier-map-form-width-desktop;

			@include mq(tablet-down) {
				padding: $mobile-padding;
				width: $supplier-map-form-width-mobile;
			}

			h2.supplier-map-form-title {
				font-weight: $font-weight-regular;
				margin-bottom: 20px;
			}

			.supplier-map-form-content-container {
				margin-bottom: 32px;
				font-size: $font-size-small-med;

				p:last-child {
					color: $color-black;
					font-weight: $font-weight-book;
					margin-bottom: 0;
				}
			}

			.supplier-map-form-container {
				form {
					$supplier-map-form-label-width: 120px;

					label {
						display: inline-block;
						width: $supplier-map-form-label-width;
						font-weight: $font-weight-semibold;
						font-size: $font-size-standard;
					}

					.supplier-map-form-select-container,
					input.supplier-map-form-input {
						margin-bottom: 12px;
					}

					.supplier-map-error {
						color: $color-error;
						font-weight: $font-weight-semibold;
						font-size: $font-size-med;
					}

					.search-suppliers-btn {
						cursor: pointer;
						display: inline-flex;
						align-items: center;
						justify-content: center;
						margin-top: 22px;
						background-color: $color-background;
						color: white;
						border-radius: 5px;
						padding: 10px 48px;
						border: none;
						font-size: $font-size-large-med;
						line-height: 24px;
						letter-spacing: 0.5px;
						font-weight: $font-weight-light;
						height: 50px;

						i {
							margin-right: 16px;
							margin-bottom: -4px;

							img {
								width: 22px;
								object-fit: contain;
							}
						}
					}

					.roi-suppliers-link {
						display: block;
						margin-top: 24px;
						font-size: $font-size-small-med;
						line-height: 23px;

						@include mq(tablet-down) {
							margin-bottom: 22px;
						}
					}

					.supplier-map-form-select-container {
						position: relative;
						display: inline-block;
						width: calc(
							100% - #{$supplier-map-form-label-width} - 4px
						);

						select {
							appearance: none;
							outline: none;
							border: 1px solid $color-medium-grey;
							border-radius: 0;
							padding: 10px;
							background-color: transparent;
							font-size: $font-size-standard;
							height: 44px;
							width: 100%;
							font-weight: $font-weight-light;
						}

						.dropdown-icon {
							position: absolute;
							top: 50%;
							right: 14px;
							transform: translateY(-50%);
							width: 12px;
							object-fit: contain;
							background-repeat: no-repeat;
							background-position: center;

							&::-ms-expand {
								display: none;
							}
						}
					}

					input.supplier-map-form-input {
						width: calc(
							100% - #{$supplier-map-form-label-width} - 4px
						);
						height: 44px;
						font-size: $font-size-standard;
						outline: none;
						border: 1px solid $color-medium-grey;
						border-radius: 0;
						padding: 10px;
						background-color: transparent;
						font-weight: $font-weight-light;
					}
				}
			}

			.supplier-map-form-company-details {
				@include mq(tablet-down) {
					margin-bottom: 16px;
				}

				.supplier-map-form-company-details-back-to-search-btn {
					display: inline-block;
					background: none;
					border: none;
					font: inherit;
					padding: 0;
					cursor: pointer;
					letter-spacing: 0.44px;
					margin-bottom: 24px;
				}

				.supplier-map-form-company-details-inner {
					font-weight: $font-weight-light;
					font-size: $font-size-med;

					h3 {
						font-size: $font-size-largest;
					}

					strong {
						display: inline-block;
						min-width: 50px;
						font-weight: $font-weight-regular;
					}

					a {
						font-weight: $font-weight-light;
					}

					img {
						width: 24px;
						object-fit: contain;
					}

					.supplier-map-form-company-details-inner-attachments-container {
						margin-top: 14px;

						.supplier-map-form-company-details-inner-attachment {
							display: flex;

							&:not(:last-of-type) {
								margin-bottom: 6px;
							}

							img {
								width: 14px;
								margin-right: 12px;
								margin-bottom: 4px;
							}
						}
					}
				}
			}
		}

		.supplier-map-results-container {
			padding: 32px 46px;
			width: calc(100% - #{$supplier-map-form-width-desktop});
			background: $color-neutral;

			@include mq(tablet-down) {
				min-height: 80vh;
				width: 100%;
				padding: 32px $mobile-padding $mobile-padding;
			}

			$mobile-bottom-padding: 126px;

			&:not(.mod-supplier-map-results-landing-visible) {
				&.mod-supplier-map-results-view-type-map {
					@include mq(tablet-up) {
						padding: 0;

						.supplier-map-results-heading {
							padding: 32px 46px 0;
						}
					}
				}

				padding-bottom: 84px;

				@include mq(tablet-down) {
					padding-bottom: $mobile-bottom-padding;
				}
			}

			&.mod-supplier-map-results-landing-visible {
				padding-right: 0;

				@include mq(tablet-down) {
					padding-right: $mobile-padding;
				}
			}

			&.mod-company-details-visible {
				padding: 0;

				.supplier-map-results-heading {
					display: none !important;
				}

				.supplier-map-results-map {
					.supplier-results-map-map-container {
						@include mq(mobile) {
							margin-left: 0;
						}
					}
				}
			}

			.supplier-map-loading-overlay {
				display: flex;
				justify-content: center;
			}

			.supplier-map-landing {
				h2 {
					margin-bottom: 18px;

					@include mq(tablet-down) {
						font-size: $font-size-larger-larger;
						line-height: 38px;
						margin-bottom: 30px;
						max-width: 90%;
					}
				}

				.supplier-map-landing-list-intro-container {
					width: $supplier-map-form-width-desktop;
					font-size: $font-size-large;
					line-height: 29px;
					margin-bottom: 48px;

					@include mq(tablet-down) {
						margin-bottom: 24px;
					}

					@include mq(tablet-down) {
						width: 100%;
					}
				}

				.list-supplier-map-landing-list-container {
					display: flex;

					ul.list-supplier-map-landing-list {
						@extend %list-default;

						width: $supplier-map-form-width-desktop;

						li {
							display: flex;
							align-items: center;
							margin-bottom: 28px;

							.supplier-map-landing-list-icon-container {
								margin: 0 28px 0 8px;

								@include mq(tablet-down) {
									margin-left: 0;
								}

								img {
									height: 50px;
									width: 50px;
									object-fit: contain;
								}
							}

							.supplier-map-landing-list-content-container {
								border-bottom: 1px solid #cccccc;

								strong {
									font-weight: $font-weight-regular;
									font-size: $font-size-large-med;
									line-height: 30px;
								}

								p {
									font-size: $font-size-small-med;
									line-height: 23px;
								}
							}
						}
					}

					.supplier-map-landing-list-image-container {
						display: flex;
						width: calc(100% - #{$supplier-map-form-width-desktop});

						@include mq(tablet-down) {
							display: none;
						}

						img {
							height: 100%;
							max-width: 393px;
							object-fit: cover;
							margin-left: auto;
							width: 100%;
						}
					}
				}
			}

			.supplier-map-results-list,
			.supplier-map-results-map {
				.supplier-map-results-heading {
					display: flex;
					margin-bottom: 36px;

					@include mq(tablet-down) {
						align-items: center;
					}

					h2 {
						font-weight: $font-weight-regular;
						line-height: 40px;
						margin-bottom: 0;

						@include mq(tablet-down) {
							font-size: $font-size-larger-larger;
						}
					}

					.supplier-map-results-view-mode-buttons-container {
						display: flex;
						margin-left: auto;

						button {
							background-color: white;
							border: 1px solid $color-medium-grey;
							padding-top: 13px;
							padding-bottom: 14px;
							cursor: pointer;

							&:first-child {
								border-radius: 22px 0 0 22px;
								padding-left: 20px;
								padding-right: 14px;
							}

							&:last-child {
								border-radius: 0 22px 22px 0;
								padding-right: 20px;
								padding-left: 14px;
								color: $color-background;
							}

							@include mq(tablet-down) {
								width: 70px;
							}

							.mobile-button-text {
								display: none;
							}

							@include mq(tablet-down) {
								.desktop-button-text {
									display: none;
								}

								.mobile-button-text {
									display: inline-block;
								}
							}

							i {
								margin-right: 8px;

								@include mq(tablet-down) {
									display: none;
								}

								img {
									width: 12px;
									object-fit: contain;
								}
							}
						}
					}
				}
			}

			.supplier-map-results-map {
				display: flex;
				flex-direction: column;
				height: 100%;

				@include mq(tablet-up) {
					height: calc(100vh - 98px);
				}

				.supplier-results-map-map-container {
					height: 100%;

					@include mq(tablet-down) {
						height: 70vh;
						width: 100vw;

						// Remove the big gap at the bottom of the element that we want when viewing the list view.
						margin-bottom: -$mobile-bottom-padding;
					}

					@include mq(mobile) {
						// Sit the element flush to the left of the screen, in conjunction with width: 100vw.
						margin-left: -$mobile-padding;
					}

					.map-tooltip {
						display: none;
						position: absolute;
						top: 0;
						left: 0;
						z-index: 999;
						width: 350px;
						margin-top: -20px;
						height: 170px;
						background-image: url(assets/images/tooltip.png);
						background-repeat: no-repeat;
						background-size: contain;
						background-position: center;

						@include mq(tablet-down) {
							margin-left: 3px;
						}

						&:after {
							content: "";
							background: $color-white;
							border: 1px solid $color-background;
							border-left: none;
							border-top: none;
							bottom: 0;
							display: block;
							height: 20px;
							left: 50%;
							position: absolute;
							transform: translateX(-50%) rotate(45deg);
							width: 20px;
						}

						.map-tooltip-inner {
							height: calc(100% - 10px);
							padding: 18px 18px 18px 24px;
							border-radius: 8px;
							border: 1px solid $color-background;
							background-color: $color-white;
							box-shadow: 9px 16px 34px 1px rgba(0, 0, 0, 0.28);

							.map-tooltip-inner-header {
								display: flex;
								align-items: center;
								margin-bottom: 12px;

								h3 {
									font-weight: $font-weight-regular;
									font-size: 22 / $font-base * 1rem;
									margin-bottom: 0;
									width: 250px;
									white-space: nowrap;
									overflow: hidden;
									text-overflow: ellipsis;
								}

								.map-tooltip-view-details-btn {
									background: none;
									border: none;
									color: $color-background;
									font: inherit;
									padding: 0;
									cursor: pointer;
									color: $color-background;
									letter-spacing: 0.44px;
									margin-left: auto;
								}
							}

							.map-tooltip-content {
								color: $color-black;
								font-size: $font-size-med;
								font-weight: $font-weight-book;

								strong {
									display: inline-block;
									min-width: 50px;
									font-weight: $font-weight-regular;
								}
							}
						}
					}

					// Hide Google Maps stuff
					.gm-fullscreen-control {
						display: none !important;
					}
				}
			}

			.supplier-map-results-list {
				ul.list-supplier-map-results {
					@extend %list-default;

					.supplier-map-result-card-template.supplier-map-result-card-template {
						display: none;
					}

					li.supplier-map-result-card {
						background: $color-white;
						border: 1px solid $color-neutral;
						border-radius: 23px;
						padding: 22px;
						margin-bottom: 10px;

						.supplier-map-result-card-heading {
							display: flex;

							@include mq(tablet-down) {
								flex-direction: column;
								margin-bottom: 28px;
							}

							> h3 {
								font-weight: $font-weight-regular;

								@include mq(tablet-down) {
									margin-bottom: 8px;
								}
							}

							> span {
								margin-left: auto;

								@include mq(tablet-down) {
									margin-left: 0;
								}
							}

							span {
								font-size: $font-size-med;
								font-weight: $font-weight-book;
								line-height: 23px;
								display: inline-block;
								margin-right: 28px;
							}

							a {
								font-size: $font-size-med;
								line-height: 23px;
							}

							.list-supplier-map-results-view-details-btn {
								color: $color-background;
								cursor: pointer;
								font-weight: $font-weight-book;
							}
						}

						.supplier-map-result-card-content {
							display: flex;

							@include mq(tablet-down) {
								flex-direction: column;
							}

							> .supplier-map-result-card-details {
								font-weight: $font-weight-book;
								width: 360px;
								padding-right: 30px;
								font-size: $font-size-med;
								line-height: 23px;

								strong {
									display: inline-block;
									min-width: 50px;
									font-weight: $font-weight-regular;
								}

								&:last-child {
									@include mq(tablet-down) {
										margin-top: 2ch;
									}
								}
							}
						}
					}
				}

				.supplier-map-results-paginator {
					display: flex;
					align-items: center;
					margin-top: 24px;

					.supplier-map-results-paginator-btn {
						display: inline-block;
						font-size: $font-size-large;
						padding-left: 0.3rem;
						padding-right: 0.3rem;
						text-align: center;
						color: $color-text;

						svg {
							margin-bottom: -2px;
						}

						&.mod-active {
							font-weight: $font-weight-semibold;
							text-decoration: underline;
						}
					}
				}
			}
		}
	}

	.lds-ellipsis {
		display: inline-block;
		position: relative;
		width: 80px;
		height: 80px;

		div {
			position: absolute;
			top: 33px;
			width: 13px;
			height: 13px;
			border-radius: 50%;
			background: #fff;
			animation-timing-function: cubic-bezier(0, 1, 1, 0);
			background: $color-background;

			&:nth-child(1) {
				left: 8px;
				animation: lds-ellipsis1 0.6s infinite;
			}

			&:nth-child(2) {
				left: 8px;
				animation: lds-ellipsis2 0.6s infinite;
			}

			&:nth-child(3) {
				left: 32px;
				animation: lds-ellipsis2 0.6s infinite;
			}

			&:nth-child(4) {
				left: 56px;
				animation: lds-ellipsis3 0.6s infinite;
			}
		}
	}

	@keyframes lds-ellipsis1 {
		0% {
			transform: scale(0);
		}

		100% {
			transform: scale(1);
		}
	}

	@keyframes lds-ellipsis3 {
		0% {
			transform: scale(1);
		}

		100% {
			transform: scale(0);
		}
	}

	@keyframes lds-ellipsis2 {
		0% {
			transform: translate(0, 0);
		}

		100% {
			transform: translate(24px, 0);
		}
	}

	.mod-hidden {
		display: none !important;
	}

	.mod-invisible {
		visibility: hidden !important;
	}
}
