#home {
	.grecaptcha-badge {
		display: none;
	}

	.section-home-hero {
		background-color: $color-white;
		overflow: hidden;
		position: relative;
		z-index: 8;

		.sub-cta-wrapper {
			display: flex;
			flex-direction: column;
			height: 429px;
			justify-content: center;
			margin: 0 auto;
			max-width: 935px;
			width: 100%;

			@include mq(tablet-down) {
				height: 345px;
			}

			.hero-header-title {
				font-family: $font-standard;
				font-size: 48px;
				font-weight: $font-weight-light;
				line-height: 54px;
				margin-bottom: 0;

				@include mq(tablet-down) {
					font-size: 31px;
					line-height: 36px;
					margin: 0 auto;
					width: 92%;
				}

				&__bold {
					@extend .hero-header-title;

					font-weight: $font-weight-regular;
				}
			}

			.list-button-links {
				display: flex;
				margin: 50px 0 0 0;

				@include mq(tablet-down) {
					margin: 50px auto 0 auto;
					width: 92%;
				}

				@include mq(mobile) {
					justify-content: center;
				}

				.item-button-link {
					margin: 0 8px 0 0;

					@include mq(tablet-down) {
						margin: 0 20px 0 0;
					}

					&:last-child {
						margin: 0;
					}

					> a {
						font-weight: $font-weight-book;
						margin: 0 auto;

						@include mq(tablet-down) {
							font-size: 14px;
						}
					}
				}
			}
		}

		.sub-images-wrapper {
			display: flex;
			height: 100%;
			justify-content: space-between;
			margin: 0 auto;
			max-width: 1505px;
			padding-bottom: 155px;
			position: relative;
			width: 100%;

			@include mq(tablet-down) {
				left: -40px;
				padding-bottom: 50px;
				width: calc(100% + 80px);
			}

			@include mq(mobile) {
				display: none;
			}

			&--desktop {
				display: none;

				@include mq(mobile) {
					background-position: center;
					background-repeat: no-repeat;
					background-size: contain;
					display: block;
					height: 230px;
					width: 100%;
				}
			}

			.hero-image {
				background-position: center;
				background-size: cover;
				background-repeat: no-repeat;
				border-radius: 25px;
				height: 375px;
				margin-top: 75px;
				overflow: hidden;
				width: 350px;

				@include mq(mobile) {
					height: 136px;
					margin-top: 25px;
					width: 126px;
				}

				&:nth-child(2) {
					@extend .hero-image;

					border-radius: 25px;
					height: 404px;
					margin-left: 45px;
					margin-top: 150px;
					width: 330px;

					@include mq(tablet-down) {
						margin-left: 25px;
					}

					@include mq(mobile) {
						height: 136px;
						margin-left: 15px;
						margin-top: 55px;
						width: 111px;
					}
				}

				&:nth-child(3) {
					@extend .hero-image;

					border-radius: 25px;
					height: 395px;
					margin-left: 45px;
					margin-top: 0;
					width: 294px;

					@include mq(tablet-down) {
						margin-left: 25px;
					}

					@include mq(mobile) {
						height: 134px;
						margin-left: 15px;
						width: 99px;
					}
				}

				&:nth-child(4) {
					@extend .hero-image;

					border-radius: 25px;
					margin-left: 45px;

					@include mq(tablet-down) {
						margin-left: 25px;
					}

					@include mq(mobile) {
						height: 139px;
						margin-left: 15px;
						width: 145px;
					}
				}
			}

			.list-home-icons {
				@extend .sub-images-wrapper;

				@extend %list-default;

				height: 100%;
				left: 0;
				position: absolute;
				top: 0;

				@include mq(tablet-down) {
					left: 40px;
				}

				> .item-home-icon {
					align-items: center;
					border-radius: 50%;
					display: flex;
					filter: drop-shadow(15px 15px 29px rgba(0, 0, 0, 0.26));
					justify-content: center;
					position: absolute;

					@include mq(mobile) {
						filter: drop-shadow(2px 0px 9px rgba(0, 0, 0, 0.25));
					}

					&.icon-police {
						background-color: #aeaeae;
						bottom: 230px;
						height: 84px;
						left: 5%;
						width: 84px;

						@include mq(mobile) {
							bottom: 15px;
							height: 28px;
							left: 1%;
							width: 28px;
						}

						> svg {
							height: 41px;
							position: relative;
							width: 41px;

							@include mq(mobile) {
								height: 16.59px;
								width: 13.48px;
							}
						}
					}

					&.icon-fire {
						background-color: #003e7e;
						height: 114px;
						left: 25%;
						top: 85px;
						width: 114px;

						@include mq(mobile) {
							height: 38px;
							left: 14%;
							top: 34px;
							width: 38px;
						}

						> svg {
							height: 56.9px;
							position: relative;
							width: 35px;

							@include mq(mobile) {
								height: 18.74px;
								width: 11.45px;
							}
						}
					}

					&.icon-alert {
						background-color: #608beb;
						bottom: 125px;
						height: 84px;
						left: 50.4%;
						width: 84px;

						@include mq(mobile) {
							bottom: 48px;
							height: 28px;
							left: 33.5%;
							width: 28px;
						}

						> svg {
							height: 41px;
							position: relative;
							width: 44px;

							@include mq(mobile) {
								height: 14.59px;
								width: 16.15px;
							}
						}
					}

					&.icon-cctv {
						background-color: #3bc597;
						height: 84px;
						left: 69%;
						top: 85px;
						width: 84px;

						@include mq(mobile) {
							height: 28px;
							left: 48%;
							top: 25px;
							width: 28px;
						}

						> svg {
							height: 44px;
							position: relative;
							width: 49px;

							@include mq(mobile) {
								height: 14.52px;
								width: 16.59px;
							}
						}
					}

					&.icon-certificate {
						background-color: #003e7e;
						height: 107px;
						left: 89%;
						top: 68px;
						width: 107px;

						@include mq(mobile) {
							bottom: 10px;
							height: 28px;
							left: 53%;
							top: unset;
							width: 28px;
						}

						> svg {
							height: 52px;
							position: relative;
							width: 41px;

							@include mq(mobile) {
								height: 15px;
								width: 14px;
							}
						}
					}
				}
			}
		}
	}

	.section-benefits {
		background-color: $color-white;
		overflow: hidden;
		position: relative;
		z-index: 8;

		.sub-content {
			@extend %container;

			display: flex;

			@include mq(tablet-down) {
				flex-direction: column;
			}

			.sub-content-left-wrapper {
				width: 50%;

				@include mq(tablet-down) {
					width: 100%;
				}

				h2 {
					font-family: $font-standard;
					font-size: 30px;
					font-weight: $font-weight-medium;
					line-height: 60px;

					@include mq(tablet-down) {
						font-size: 25px;
					}
				}

				p {
					font-family: $font-standard;
					font-size: 17px;
					font-weight: $font-weight-book;
					line-height: 30px;

					> a {
						color: $color-buttons;
					}

					@include mq(tablet-down) {
						font-size: 15px;
						line-height: 28px;
					}
				}

				.list-home-benefits {
					@extend %list-default;

					margin-top: 85px;

					@include mq(tablet-down) {
						margin-top: 42px;
					}

					.item-home-benefit {
						display: flex;
						margin-bottom: 45px;

						@include mq(tablet-down) {
							margin-bottom: 25px;
						}

						&:nth-last-child(1) {
							margin-bottom: 0;
						}

						img {
							align-self: center;
							height: 100%;
							margin-right: 35px;
							width: 67px;

							@include mq(tablet-down) {
								align-self: flex-start;
								margin-right: 25px;
								width: 51px;
							}
						}

						h5 {
							font-family: $font-standard;
							font-size: 17px;
							font-weight: $font-weight-medium;
							height: fit-content;
							line-height: 10px;
							margin: 0 0 20px 0;
							width: 100%;
						}

						p {
							font-family: $font-standard;
							font-size: 15px;
							font-weight: $font-weight-book;
							height: fit-content;
							line-height: 23px;
							margin: 0;
							width: 100%;
						}
					}
				}
			}

			.sub-content-right-wrapper {
				display: flex;
				justify-content: flex-end;
				width: 50%;

				@include mq(tablet-down) {
					width: 100%;
				}

				.feature-card {
					margin-top: 90px;
					width: 436px;

					@include mq(tablet-down) {
						display: flex;
						margin-top: 55px;
						width: 100%;
					}

					@include mq(mobile) {
						display: block;
					}

					.feature-card-header {
						background-position: center;
						background-repeat: no-repeat;
						background-size: cover;
						border-radius: 25px 0 0 25px;
						height: 327px;
						width: 100%;

						@include mq(tablet-down) {
							border-radius: 0;
						}

						@include mq(mobile) {
							border-radius: 25px 25px 0 0;
							max-height: 248px;
						}
					}

					.feature-card-content {
						background-color: $color-tertiary;
						border-radius: 0 0 25px 25px;
						padding: 35px 40px;

						@include mq(tablet-down) {
							border-radius: 0;
						}

						@include mq(mobile) {
							padding: 20px 15px 25px 15px;
						}

						h5 {
							color: $color-white;
							font-family: $font-standard;
							font-size: 17px;
							font-weight: $font-weight-regular;
							letter-spacing: 0.6px;
							line-height: 30px;

							@include mq(tablet-down) {
								margin: 0;
							}
						}

						p {
							color: $color-white;
							font-family: $font-standard;
							font-size: 15px;
							font-weight: $font-weight-book;
							line-height: 23px;
							margin-top: 33px;

							> a {
								color: $color-buttons;
							}

							@include mq(tablet-down) {
								margin: 15px 0 25px 0;
							}
						}

						a {
							@extend %animation-right;

							color: $color-white;
							font-family: $font-standard;
							font-size: 16px;
							font-weight: $font-weight-regular;
							letter-spacing: 0.6px;
							line-height: 22px;

							@include mq(tablet-down) {
								font-size: 15px;
								line-height: 21px;
							}

							> svg {
								color: white;
								height: 11px;
								margin-left: 15px;
								margin-top: 2px;
								overflow: hidden;
								width: 20px;

								@include mq(tablet-down) {
									margin-top: 3px;
								}
							}
						}
					}
				}
			}
		}
	}

	.section-content-boxes {
		background-color: $color-white;
		overflow: hidden;
		padding: 100px 0 125px 0;
		position: relative;
		z-index: 8;

		@include mq(tablet-down) {
			padding: 50px 0 22px 0;
		}

		.sub-content {
			@extend %container;

			h2 {
				font-family: $font-standard;
				font-size: 30px;
				font-weight: $font-weight-medium;
				line-height: 60px;
				margin-bottom: 50px;

				@include mq(tablet-down) {
					font-size: 25px;
					margin-bottom: 10px;
				}
			}

			.list-home-content-boxes {
				@extend %list-default;

				display: flex;

				@include mq(tablet-down) {
					flex-direction: column;
				}

				.item-home-content-box {
					max-width: 436px;
					width: calc(100% / 3);

					&:not(:first-child) {
						margin-left: 32px;

						@include mq(tablet-down) {
							margin-left: 0;
						}
					}

					@include mq(tablet-down) {
						display: flex;
						margin-bottom: 33px;
						max-width: unset;
						width: 100%;
					}

					@include mq(mobile) {
						display: block;
						margin-bottom: 33px;
						width: 100%;
					}

					.content-box-image {
						background-position: center;
						background-repeat: no-repeat;
						background-size: cover;
						height: 186px;
						width: 100%;

						@include mq(tablet-down) {
							height: unset;
						}

						@include mq(mobile) {
							height: 146px;
						}
					}

					.content-box-content {
						background-color: $color-neutral;
						border-radius: 0 0 25px 25px;
						padding: 25px 40px;

						@include mq(tablet-down) {
							border-radius: 0;
							padding: 20px 15px 25px 15px;
						}

						@include mq(mobile) {
							border-radius: 0 0 25px 25px;
						}

						h5 {
							font-family: $font-standard;
							font-size: 19px;
							font-weight: $font-weight-medium;
							letter-spacing: 0.6px;
							line-height: 30px;

							@include mq(tablet-down) {
								font-size: 17px;
								line-height: 30px;
								margin-bottom: 10px;
							}
						}

						p {
							font-family: $font-standard;
							font-size: 15px;
							font-weight: $font-weight-book;
							line-height: 23px;
							margin-bottom: 38px;

							> a {
								color: $color-buttons;
							}

							@include mq(tablet-down) {
								font-size: 14px;
								margin-bottom: 20px;
							}
						}

						a {
							@extend %animation-right;

							color: $color-buttons;
							font-family: $font-standard;
							font-size: 16px;
							font-weight: $font-weight-regular;
							letter-spacing: 0.6px;
							line-height: 22px;

							@include mq(tablet-down) {
								font-size: 15px;
								line-height: 21px;
							}

							> svg {
								color: $color-buttons;
								height: 11px;
								margin-left: 15px;
								margin-top: 2px;
								overflow: hidden;
								width: 20px;
							}
						}
					}
				}
			}
		}
	}

	.section-news-and-social-feed {
		background-color: $color-primary;
		overflow: hidden;
		padding: 100px 0 115px 0;
		position: relative;
		z-index: 8;

		@include mq(tablet-down) {
			padding: 50px 0;
		}

		.sub-content {
			@extend %container;

			display: flex;

			@include mq(tablet-down) {
				flex-direction: column;
			}

			.sub-latest-news-wrapper {
				width: calc(((100% / 3) * 2) - 25px);

				@include mq(tablet-down) {
					width: 100%;
				}

				> h2 {
					color: $color-white;
					font-family: $font-standard;
					font-size: 30px;
					font-weight: $font-weight-regular;
					line-height: 60px;

					@include mq(tablet-down) {
						font-size: 25px;
					}
				}

				.sub-news-wrapper {
					display: flex;
					width: 100%;

					@include mq(tablet-down) {
						flex-direction: column;
					}

					.news-content {
						background-color: $color-secondary;
						border-radius: 25px 0 0 25px;
						max-width: 380px;
						padding: 55px 40px;
						width: 100%;

						@include mq(tablet-down) {
							border-radius: 25px 25px 0 0;
							max-width: unset;
							padding: 30px 20px 10px 20px;
						}

						p.news-date {
							color: $color-tertiary;
							font-size: 13px;
							font-weight: $font-weight-book;
							line-height: 23px;
						}

						p.news-copy {
							color: $color-white;
							font-size: 15px;
							font-weight: $font-weight-book;
							line-height: 23px;
						}

						h4 {
							color: $color-white;
							font-size: 20px;
							font-weight: $font-weight-regular;
							line-height: 27px;
						}

						a {
							color: $color-tertiary;
							font-size: 15px;
							font-weight: $font-weight-book;
							line-height: 23px;

							@include mq(tablet-down) {
								display: none;
							}
						}
					}

					.news-image {
						background-position: center;
						background-repeat: no-repeat;
						background-size: cover;
						width: 100%;

						@include mq(tablet-down) {
							height: 232px;
						}
					}
				}
			}

			.sub-twitter-feed-wrapper {
				align-items: baseline;
				display: flex;
				flex-direction: column;

				// justify-content: space-between;
				margin-left: auto;
				width: calc((100% / 3) * 1);

				@include mq(tablet-down) {
					margin: 0;
					width: 100%;
				}

				.twitter-header {
					align-items: baseline;
					display: flex;
					margin: 15px 0 10px 0;
					width: 100%;

					@include mq(tablet-down) {
					}

					> h2 {
						color: $color-white;
						font-family: $font-standard;
						font-size: 30px;
						font-weight: $font-weight-regular;
						line-height: 60px;
						margin-bottom: 0;

						@include mq(tablet-down) {
							font-size: 25px;
							margin: 0;
						}
					}

					> a {
						margin-left: auto;

						svg {
							color: $color-white;
							height: 20px;

							@include mq(tablet-down) {
							}
						}
					}
				}

				.tweet-wrapper {
					display: flex;
					flex-direction: column;
					width: 100%;
					position: relative;

					.list-tweets {
						@extend %list-default;

						.item-tweet {
							background-color: #21568e;
							border-radius: 19px;
							padding: 15px 21px;
							margin-bottom: 5px;

							p.tweet-text {
								color: $color-white;
								font-size: 13px;
								font-weight: $font-weight-light;
								line-height: 20px;
								margin-bottom: 8px;
							}

							p.tweet-date {
								color: #3bc597;
								font-size: 13px;
								font-weight: $font-weight-book;
								line-height: 23px;
								margin: 0;
							}
						}
					}
				}
			}
		}
	}
}
