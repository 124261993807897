#search {
	.section-search-bar {
		align-items: center;
		background-color: #F0F0F0;
		display: flex;
		height: 175px;

		@include mq(tablet-down) {
			height: 124px;
		}


		.sub-content {
			@extend %container;

			.search-wrap {
				max-width: 800px;
				position: relative;

				input {
					background: $color-white;
					border-radius: 10px;
					border: none;
					font-size: 19px;
					font-weight: $font-weight-regular;
					height: 67px;
					outline: none;
					padding: 0 10px 0 22px;
					width: 100%;
				}

				button {
					height: 50px;
					font-size: $font-size-standard;
					font-weight: $font-weight-regular;
					justify-content: center;
					margin-top: 0;
					max-width: 90px !important;
					padding: 0 20px;
					position: absolute;
					right: 10px;
					text-align: center;
					top: 10px;

					@include mq(tablet-down) {
						height: 48px;
						padding: 0 5px;
						width: 48px;
					}
				}
			}
		}
	}

	.section-main-section {
		padding: 60px 0 120px;

		@include mq(tablet-down) {
			padding: 40px 0;
			position: relative;
		}


		.sub-main-filter-btn {
			display: none;

			@include mq(tablet-down) {
				cursor: pointer;
				display: block;
				position: absolute;
				right: 16px;
				top: 47px;
				z-index: 999;

				svg {
					height: 20px;
					width: 22px;
					z-index: 999;
				}

				&.mod-hide {
					display: none;
					pointer-events: none;
				}
			}
		}

		.sub-content {
			@extend %container;

			display: flex;

			@include mq(tablet-down) {
				flex-direction: column;
			}


			.aside-filters {
				align-self: flex-start;
				background-color: $color-white;
				border-radius: 10px;
				box-shadow: 0 6px 13px 0 rgba($color-black, 0.08);
				padding: 26px 17px;
				width: 295px;

				@include mq(tablet-down) {
					border: none;
					border-radius: 0;
					box-shadow: none;
					max-height: 0;
					overflow: hidden;
					padding: 0;
					transition: all .3s ease;
					width: 100%;

					svg {
						display: none;
					}
				}


				&.mod-active {
					@include mq(tablet-down) {
						max-height: 2000px;
						padding: 15px 0;
						transition: all .3s ease;

						svg {
							display: block;
						}
					}
				}

				header {
					.sub-filter-mob {
						display: none;
					}

					@include mq(tablet-down) {
						align-items: center;
						display: flex;
						margin-bottom: 35px;

						.sub-filter-mob {
							display: block;
							cursor: pointer;
							margin-left: auto;

							svg {
								height: 20px;
								width: 22px;
							}
						}
					}


					h4 {
						font-size: $font-size-large;
						font-weight: $font-weight-medium;

						@include mq(tablet-down) {
							margin-bottom: 0;
						}
					}
				}

				.sub-filter {
					.sub-title {
						display: flex;

						h5 {
							font-size: $font-size-standard;
							font-weight: $font-weight-medium;
							margin-bottom: 0;
						}

						.sub-reset {
							color: $color-background;
							cursor: pointer;
							font-size: 13px;
							font-weight: $font-weight-regular;
							margin-left: auto;

							@include mq(tablet-down) {
								font-size: 15px;
							}
						}
					}

					.list-categories {
						@extend %list-default;

						border-bottom: 1px solid #DFDFDF;
						padding: 18px 0 25px;
						margin-bottom: 25px;

						& > li {
							display: flex;
							margin-bottom: 16px;

							.sub-checkbox-wrap {
								cursor: pointer;
								display: flex;
								position: relative;
								width: 100%;

								input {
									opacity: 0;
									width: 0;
								}

								.sub-checkbox {
									cursor: pointer;
									margin-left: -28px;
									z-index: 8;
								}

								&:before {
									background: $color-white;
									border: 1px solid #DFDFDF;
									content: '';
									display: inline-block;
									height: 17px;
									margin-right: 9px;
									margin-top: 2px;
									transition: background .3s ease;
									min-width: 17px;
								}

								&:after {
									content: '';
									opacity: 0;
									transition: opacity .3s ease;
								}

								&.mod-checked {
									&:before {
										background: $color-white;
									}

									&:after {
										border: solid $color-primary;
										border-width: 0 2px 2px 0;
										display: block;
										height: 7px;
										left: 7px;
										opacity: 1;
										position: absolute;
										top: 6px;
										transform: rotate(45deg);
										width: 3px;
									}
								}
							}

							label {
								cursor: pointer;
								padding-left: 30px;
								flex: 1;
								position: relative;
								z-index: 999;
							}

							.sub-count {
								color: #9A9A9A;
								font-size: 13px;
								font-weight: $font-weight-regular;
							}
						}
					}

					&:last-of-type .list-categories {
						border-bottom: none;
						margin-bottom: 0;
						padding-bottom: 0;
					}
				}
			}

			.section-results {
				flex: 1;
				margin-left: 65px;
				padding: 25px 0;
				position: relative;

				@include mq(tablet-down) {
					margin-left: 0;
					padding: 0;
				}


				header {
					align-items: center;
					display: flex;
					width: 100%;

					h2 {
						font-size: $font-size-h3;
						font-weight: $font-weight-regular;
						flex: 1;
						margin-bottom: 0;
					}

					.mod-select {
						position: relative;

						select {
							appearance: none;
							background-color: $color-white;
							border-radius: 10px;
							border: 1px solid #EDEDED;
							font-size: 13px;
							height: 38px;
							padding: 0 45px 0 15px;
						}

						img {
							position: absolute;
							right: 15px;
							top: 15px;
							color: $color-primary;
							transform: rotate(90deg);
							height: auto;
							width: 8px;
						}
					}
				}

				.list-results {
					@extend %list-default;

					display: flex;
					flex-direction: column;
					padding-top: 30px;

					& > li {
						align-items: center;
						border-bottom: 1px solid #E2E2E2;
						display: flex;
						min-height: 50px;
						position: relative;

						&:first-of-type {
							border-top: 1px solid #E2E2E2;
						}

						.sub-link {
							height: 100%;
							left: 0;
							position: absolute;
							top: 0;
							width: 100%;
						}

						.sub-title {
							color: $color-background;
							font-size: 15px;
							font-weight: $font-weight-regular;
							margin-bottom: 0;
						}

						.sub-cat {
							color: #B1B1B1;
							font-size: 15px;
							font-weight: $font-weight-regular;
							margin-left: auto;
							max-width: 220px;
							width: 100%;

							@include mq(tablet-down) {
								display: none;
							}
						}

						img {
							color: $color-primary;
							height: auto;
							width: 8px;

							@include mq(tablet-down) {
								display: none;
							}
						}
					}
				}

				.list-pagination {
					@extend %list-default;

					display: flex;
					margin-top: 30px;

					& > li {
						border-radius: 10px;
						border: 1px solid #EDEDED;
						box-shadow: 0 6px 13px 0 rgba($color-black, 0.08);
						margin-right: 5px;

						a {
							align-items: center;
							color: $color-background;
							display: flex;
							justify-content: center;
							height: 41px;
							width: 41px;
						}

						&.mod-active {
							a {
								cursor: default;
								color: $color-black;
								pointer-events: none;
							}
						}
					}
				}
			}
		}
	}

	.loading_overlay {
		background: $color-white;
		display: none;
		height: 100%;
		left: 0;
		position: absolute;
		text-align: center;
		top: 0;
		width: 100%;
		z-index: 9999;

		img {
			position: absolute;
			top: 50%;
			width: 60px;
		}
	}

	footer {
		position: static;
		opacity: 1 !important;
	}
}
