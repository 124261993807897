#careers {
	.section-hero {
		background-color: $color-white;
		overflow: hidden;
		padding-bottom: 135px;
		padding-top: 90px;
		position: relative;
		z-index: 8;

		@include mq(tablet-down) {
			padding-bottom: 50px;
			padding-top: 35px;
		}


		.sub-content {
			@extend %container;

			display: flex;

			@include mq(desktop) {
				margin: 0 auto;
			}


			@include mq(tablet-down) {
				flex-direction: column;
			}


			.sub-hero-content {
				width: 50%;
				z-index: 8;

				@include mq(tablet-down) {
					width: 100%;
				}


				h1 {
					font-size: 40px;
					font-weight: $font-weight-medium;
					line-height: 54px;

					@include mq(tablet-down) {
						font-size: 25px;
						line-height: 60px;
					}
				}

				p {
					font-size: 22px;
					font-weight: $font-weight-book;
					line-height: 36px;
					max-width: 496px;
					width: 100%;

					@include mq(tablet-down) {
						font-size: 15px;
						line-height: 28px;
					}
				}

				.list-button-links {
					@extend %list-default;

					display: flex;
					margin: 50px 0 0 0;

					@include mq(tablet-down) {
						flex-direction: column;
						justify-content: center;
						margin: 30px auto 50px auto;
						width: 100%;
					}


					.item-button-link {
						margin: 0 8px 0 0;
						text-align: center;
						width: 210px;

						@include mq(tablet-down) {
							margin: 0 0 15px 0;
							width: 100%;
						}


						&:last-child {
							margin: 0;
						}

						> a, button {
							font-weight: $font-weight-book;
							margin: 0;
							justify-content: center;

							@include mq(tablet-down) {
								font-size: 14px;
								max-width: 100%;
								width: 100%;
							}
						}
					}
				}
			}

			.sub-hero-image {
				display: flex;
				justify-content: flex-end;
				position: relative;
				width: 50%;

				@include mq(tablet-down) {
					width: 100%;
				}


				.hero-content-image {
					background-position: center;
					background-repeat: no-repeat;
					background-size: cover;
					border-radius: 25px;
					display: block;
					height: 488px;
					position: relative;
					max-width: 679px;
					width: 100%;

					@include mq(desktop) {
						border-radius: 25px;
					}


					@include mq(tablet-down) {
						height: 398px;
						max-width: unset;
					}


					@include mq(mobile) {
						height: 236px;
						border-radius: 25px;
					}
				}

				.list-hero-icons {
					@extend %list-default;

					display: flex;
					height: 100%;
					left: 0;
					position: absolute;
					top: 0;
					width: 100%;

					@include mq(tablet-down) {
						left: 0;
					}


					> .item-hero-icon {
						align-items: center;
						border-radius: 50%;
						display: flex;
						filter: drop-shadow(15px 15px 29px rgba(0, 0, 0, 0.26));
						justify-content: center;
						position: absolute;

						@include mq(tablet-down) {
							filter: drop-shadow(15px 15px 13px rgba(0, 0, 0, 0.26));
						}


						&.icon-ukas {
							background-color: #F0F0F0;
							bottom: 85px;
							height: 90px;
							left: -42px;
							width: 90px;

							@include mq(tablet-down) {
								bottom: 25px;
								height: 85px;
								left: 3%;
								width: 85px;
							}


							@include mq(mobile) {
								height: 45px;
								width: 45px;
							}


							> svg {
								height: 55px;
								position: relative;
								width: 39px;

								@include mq(tablet-down) {
									height: 55px;
									width: 40px;
								}


								@include mq(mobile) {
									height: 30px;
								}
							}
						}

						&.icon-cctv {
							background-color: #3bc597;
							height: 100px;
							left: 28%;
							top: -40px;
							width: 100px;

							@include mq(tablet-down) {
								height: 85px;
								left: 22%;
								top: -22px;
								width: 85px;
							}


							@include mq(mobile) {
								height: 45px;
								width: 45px;
							}


							> svg {
								height: 52px;
								position: relative;
								width: 58px;

								@include mq(tablet-down) {
									height: 44.96px;
									width: 39.84px;
								}


								@include mq(mobile) {
									height: 24.96px;
									width: 27.84px;
								}
							}
						}

						&.icon-certificate {
							background-color: #003e7e;
							height: 100px;
							left: 81%;
							bottom: -40px;
							width: 100px;

							@include mq(tablet-down) {
								bottom: -22px;
								height: 85px;
								left: 70%;
								top: unset;
								width: 85px;
							}


							@include mq(mobile) {
								height: 48px;
								width: 48px;
							}


							> svg {
								height: 52px;
								position: relative;
								width: 41px;

								@include mq(tablet-down) {
									height: 40px;
									width: 45px;
								}


								@include mq(mobile) {
									height: 25px;
									width: 19px;
								}
							}
						}
					}
				}
			}
		}
	}

	.section-careers-content {
		&--primary {
			@extend %image-content-block, %image-content-block-left;

			background-color: $color-neutral;

			@include mq(tablet-down) {
				padding: 40px 0 80px 0;
			}
		}

		&--secondary {
			@extend %image-content-block, %image-content-block-right;

			background-color: $color-white;

			@include mq(tablet-down) {
				margin-bottom: 30px;
				padding: 60px 0 0 0;
			}
		}
	}

	.section-content-banner {
		align-content: center;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		display: flex;
		height: 520px;
		justify-content: center;
		overflow: hidden;
		padding: 143px 0 141px 0;
		position: relative;
		width: 100%;
		z-index: 8;

		@include mq(tablet-down) {
			align-items: center;
			height: 448px;
			padding: unset;
		}


		.content-wrapper {
			color: $color-white;
			text-align: center;
			max-width: 1037px;
			width: 100%;

			@include mq(tablet-down) {
				max-width: unset;
				width: 91%;
			}


			p {
				font-size: 30px;
				font-weight: $font-weight-light;
				letter-spacing: 0.6px;
				line-height: 51px;
				margin: 0;

				> a {
					color: $color-buttons;
				}

				@include mq(tablet-down) {
					font-size: 22px;
					line-height: 36px;
				}


				> strong {
					font-weight: $font-weight-regular;
				}
			}

			> h5 {
				color: $color-white;
				font-size: 19px;
				font-weight: $font-weight-book;
				margin-top: 40px;

				@include mq(tablet-down) {
					margin-top: 20px;
				}
			}
		}
	}

	.section-career-opportunities {
		background-color: $color-white;
		border-bottom: 1px solid #eaeaea;
		overflow: hidden;
		padding-bottom: 122px;
		padding-top: 90px;
		position: relative;
		z-index: 8;

		@include mq(tablet-down) {
			padding-bottom: 63px;
			padding-top: 30px;
		}


		.sub-content {
			@extend %container;

			h1 {
				font-size: 30px;
				font-weight: $font-weight-medium;
				line-height: 54px;

				@include mq(tablet-down) {
					font-size: 25px;
					line-height: 60px;
				}
			}

			h2 {
				font-size: 22px;
				font-weight: $font-weight-book;
				line-height: 36px;
				margin-bottom: 40px;
				max-width: 736px;
				width: 100%;

				@include mq(tablet-down) {
					font-size: 15px;
					line-height: 28px;
				}
			}

			.list-career-oppotunities {
				@extend %list-default;

				.item-career-opportunity {
					align-items: center;
					background-color: $color-neutral;
					border-radius: 7px;
					cursor: pointer;
					display: flex;
					flex-wrap: wrap;
					height: 71px;
					justify-content: space-between;
					margin-bottom: 5px;
					padding: 0 22px;
					position: relative;

					@include mq(tablet-down) {
						align-items: unset;
						border-radius: 23px;
						flex-direction: column;
						flex-wrap: nowrap;
						height: 196px;
						justify-content: unset;
						padding: 22px;
					}


					a {
						height: 100%;
						left: 0;
						position: absolute;
						top: 0;
						width: 100%;
					}

					h5 {
						color: $color-black;
						font-size: 19px;
						font-weight: $font-weight-medium;
						line-height: 60px;
						margin: 0;

						@include mq(tablet-down) {
							font-size: 16px;
							line-height: 22px;
							max-width: 60%;
						}
					}

					.career-link-text {
						@include mq(tablet-down) {
							display: block !important;
						}


						p {
							align-items: baseline;
							color: $color-buttons;
							display: flex;
							font-size: 16px;
							font-weight: $font-weight-regular;
							height: 100%;
							justify-content: flex-end;
							line-height: 22px;
							margin: 0;

							> a {
								color: $color-buttons;
							}

							@include mq(tablet-down) {
								font-size: 15px;
								justify-content: flex-start;
							}


							> svg {
								color: $color-buttons;
								display: inline-block;
								height: 9.86px;
								margin-left: 15px;
								width: 14px;
							}
						}

						&.mod-hide {
							display: none;
						}
					}

					.career-opportunity-details {
						align-items: center;
						display: flex;
						height: 100%;

						@include mq(tablet-down) {
							align-items: unset;
							height: unset;
							margin: 15px 0 8px 0;
						}


						p {
							border-right: 1px solid rgba(#979797, 0.37);
							font-size: 16px;
							font-weight: $font-weight-regular;
							line-height: 30px;
							margin: 0;
							padding: 0 20px;

							> a {
								color: $color-buttons;
							}

							@include mq(tablet-down) {
								font-size: 12.5px;
								padding: 0 15px;
							}


							&:last-child {
								border: 0;
								padding-right: 0;
							}

							&:first-child {
								@include mq(tablet-down) {
									padding-left: 0;
								}
							}
						}

						&.mod-hide {
							display: none;
						}
					}
				}
			}
		}
	}
}
