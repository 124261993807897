#meet-the-team {
	.section-hero {
		background: $color-background;
		display: flex;
		flex-direction: column;
		height: 432px;
		justify-content: center;
		position: relative;
		overflow: hidden;
		z-index: 8;

		@include mq(mobile) {
			height: 271px;
		}


		.sub-content {
			@extend %container;

			h1 {
				color: $color-white;
				font-size: 40px;
				font-weight: $font-weight-medium;
				line-height: 54px;
			}

			p {
				color: $color-white;
				font-size: 22px;
				font-weight: $font-weight-book;
				line-height: 36px;
				max-width: 725px;
				width: 100%;
			}
		}
	}

	.section-meet-the-team {
		background-color: $color-neutral;
		overflow: hidden;
		padding: 0 0 85px 0;
		position: relative;
		z-index: 8;

		.sub-content {
			@extend %container;

			padding-top: 50px;

			.list-team-members {
				@extend %list-default;

				display: flex;
				flex-wrap: wrap;
				width: 100%;

				.item-team-member {
					background-color: $color-white;
					border-radius: 23px;
					height: 386px;
					margin-bottom: 23px;
					margin-right: 25px;
					min-width: 322px;
					padding: 21px 22px 20px 22px;
					width: calc((100% / 4) - 75px);

					&:nth-child(4n) {
						margin-right: 0;
					}

					> img {
						margin-bottom: 15px;
					}

					> h2 {
						color: $color-black;
						display: block;
						font-size: 22px;
						font-weight: $font-weight-medium;
						line-height: 30px;
						margin: 0 0 5px 0;
					}

					> h5 {
						color: $color-primary;
						display: block;
						font-size: 16px;
						font-weight: $font-weight-regular;
						line-height: 22px;
						margin: 0 0 15px 0;
					}

					> p {
						color: $color-black;
						display: -webkit-box;
						font-size: 14px;
						font-weight: $font-weight-light;
						line-height: 22px;
						height: 65px;
						margin: 0 0 17px 0;
						overflow: hidden;
						-webkit-line-clamp: 3;
						-webkit-box-orient: vertical;

						&.mod-show-more {
							display: bloxk;
							height: unset;
							overflow: unset;
							text-overflow: unset;
							-webkit-line-clamp: unset;
							-webkit-box-orient: unset;
						}
					}

					> button {
						background: none;
						border: none;
						color: $color-buttons;
						display: block;
						font-size: 17px;
						font-weight: $font-weight-regular;
						line-height: 22px;
						margin: 0;

						@include mq(tablet-down) {
							padding: 0;
						}
					}
				}
			}
		}
	}
}
