#faqs {
	.section-hero {
		background-color: $color-white;
		overflow: hidden;
		padding: 90px 0 0 0;
		margin-bottom: 40px;
		position: relative;
		z-index: 8;

		@include mq(tablet-down) {
			margin: 0;
			padding: 45px 0 30px 0;
		}

		.sub-content {
			@extend %container;

			h1 {
				color: $color-black;
				font-size: 40px;
				font-weight: $font-weight-medium;
				line-height: 54px;

				@include mq(tablet-down) {
					font-size: 25px;
					line-height: 60px;
				}
			}

			p {
				font-size: 22px;
				font-weight: $font-weight-book;
				line-height: 36px;
				max-width: 736px;
				width: 100%;

				@include mq(tablet-down) {
					font-size: 15px;
					line-height: 28px;
					max-width: unset;
				}
			}
		}
	}

	.section-faqs {
		@extend %faqs;
	}
}
