#sia-scheme {
	.section-hero {
		background: $color-background;
		display: flex;
		flex-direction: column;
		height: 432px;
		justify-content: center;
		position: relative;
		overflow: hidden;
		z-index: 8;

		@include mq(mobile) {
			height: unset;
			padding: 30px 0;
		}


		.sub-content {
			@extend %container;

			.sub-copy {
				margin-top: 20px;

				@include mq(mobile) {
					margin-top: 0;
				}


				h1 {
					color: $color-white;
					font-size: 40px;
					font-weight: $font-weight-medium;
					line-height: 54px;

					@include mq(mobile) {
						font-size: 25px;
					}
				}

				p {
					color: $color-white;
					font-size: 22px;
					font-weight: $font-weight-book;
					line-height: 36px;
					max-width: 725px;
					width: 100%;

					@include mq(mobile) {
						font-size: 15px;
						line-height: 28px;
					}
				}

				.list-button-links {
					@extend %list-default;

					display: flex;
					margin: 50px 0 0 0;

					@include mq(tablet-down) {
						margin: 50px auto 0 auto;
						width: 92%;
					}


					@include mq(mobile) {
						justify-content: flex-start;
						margin: 0;
						margin-top: 30px;
					}


					.item-button-link {
						margin: 0 8px 0 0;
						text-align: center;
						width: 157px;

						@include mq(tablet-down) {
							margin: 0 20px 0 0;
						}


						&:last-child {
							margin: 0;
						}

						> a {
							background-color: $color-white;
							color: $color-background;
							font-weight: $font-weight-book;
							margin: 0 auto;
							justify-content: center;

							@include mq(tablet-down) {
								font-size: 14px;
							}
						}
					}
				}
			}
		}
	}

	.section-content {
		background-color: $color-white;
		border-bottom: 1px solid #eaeaea;
		padding: 88px 0 105px;
		position: relative;
		z-index: 8;

		@include mq(mobile) {
			padding: 35px 0;
		}


		.sub-content {
			@extend %container;

			align-content: center;
			display: flex;

			@include mq(mobile) {
				flex-direction: column;
			}


			.sub-copy {
				flex: 1;
				margin-right: 95px;
				margin-top: 10px;

				@include mq(mobile) {
					margin-right: 0;
				}


				> h2 {
					font-size: 17px;
					font-weight: $font-weight-medium;
					line-height: 54px;
					margin-bottom: 5px;

					@include mq(mobile) {
						font-size: 17px;
						line-height: 25px;
						margin-bottom: 15px;
					}
				}

				p {
					font-size: 17px;
					font-weight: $font-weight-book;
					line-height: 21px;

					@include mq(mobile) {
						font-size: 15px;
						line-height: 28px;
					}


					> a {
						color: $color-buttons;
					}
				}

				ul {
					margin-left: 0;
					list-style-type: square;

					li {
						font-weight: $font-weight-book;
						line-height: 23px;
						margin-bottom: 20px;
						padding-left: 10px;
						font-size: 17px;
						font-weight: $font-weight-book;
						line-height: 21px;

						@include mq(mobile) {
							font-size: 15px;
							line-height: 28px;
						}


						@include mq(tablet-down) {
							margin-bottom: 15px;
						}


						&::marker {
							color: $color-buttons;
						}
					}
				}
			}

			.sub-image {
				background-position: center center;
				background-repeat: no-repeat;
				background-size: cover;
				border-radius: 25px;
				height: 384px;
				max-width: 436px;
				width: 100%;

				@include mq(mobile) {
					height: 320px;
					margin-top: 45px;
					max-width: none;
				}
			}
		}
	}
}
