.section-cookies {
	align-items: center;
	background: $color-primary;
	display: flex;
	padding: 20px 0;
	width: 100%;
	z-index: 999;

	@include mq(tablet-down) {
		height: auto;
		padding: 10px 0 20px;
	}


	&.mod-hide {
		display: none;
	}

	.sub-content {
		@extend %container-large;

		display: flex;

		@include mq(tablet-down) {
			flex-direction: column;
		}


		.sub-text {
			flex: 1;

			& > p {
				color: $color-white;
				font-size: $font-size-small;
				line-height: $line-height-base;
				margin-bottom: 0;

				@include mq(tablet-down) {
					color: rgba($color-white, 0.7);
				}


				a {
					color: $color-white;
					font-weight: $font-weight-semibold;

					@include mq(tablet-down) {
						color: rgba($color-white, 0.7);
					}
				}
			}
		}

		.sub-btns {
			align-items: center;
			display: flex;
			margin-left: 70px;

			@include mq(tablet-down) {
				margin-left: 0;
				margin-top: 20px;
			}


			.btn {
				background: none;
				border: 2px solid rgba($color-white, 0.25);
				color: $color-white;
				display: inline-block;
				font-size: $font-size-standard;
				font-family: $headings-font !important;
				padding: 5px 0;
				transition: background .3s ease;
				width: 130px;

				@include mq(tablet-down) {
					font-size: $font-size-small;
					padding: 4px 0;
					width: 115px;
				}


				&:first-of-type {
					margin-right: 10px;
				}

				&:hover {
					background: rgba($color-white, 0.25);
				}
			}
		}
	}
}

.header-main {
	align-items: center;
	border-bottom: 0.5px solid #E8E8E8;
	display: flex;
	height: 98px;
	position: relative;
	z-index: 99999;

	@include mq(tablet-down) {
		height: 69.5px;
	}


	.sub-container {
		@extend %container;

		display: flex;
		justify-content: space-between;

		.sub-logo {
			align-items: center;
			display: flex;
			width: 305px;

			@media screen and (max-width: 1424px) {
				width: 245px;
			}

			@include mq(tablet-down) {
				width: 240px;
			}


			& > svg {
				height: 63px;
				vertical-align: middle;

				@include mq(tablet-down) {
					width: 236px;
				}
			}
		}

		.mob-hamburger {
			@include mq(tablet-down) {
				align-self: center;
				cursor: pointer;
				display: flex;
				flex-direction: column;
				height: 20px;
				justify-content: center;
				margin-right: 7px;
				overflow: hidden;
				position: relative;
				top: 12px;
				transform: translateY(-50%);
				transition: width .3s ease;
				width: 20px;
				z-index: 999;

				.mob-bar {
					background: #638DEB;
					border-radius: 20px;
					display: block;
					height: 1.11px;
					left: 0;
					margin-bottom: 8px;
					opacity: 1;
					position: absolute;
					transform: rotate(0deg);
					transition: .4s ease-in-out;
					width: 100%;
					z-index: 9999;
				}

				.mob-bar-1 {
					top: 0;
				}

				.mob-bar-2 {
					top: 8px;
				}

				.mob-bar-3 {
					margin-bottom: 0;
					top: 16px;
				}

				&.mod-active {
					width: 28px;

					.mob-bar {
						background: $color-primary;
					}

					.mob-bar-1 {
						top: 9px;
						transform: rotate(135deg);
					}

					.mob-bar-2 {
						left: -60px;
						opacity: 0;
					}

					.mob-bar-3 {
						transform: rotate(-135deg);
						top: 9px;
						width: 100%;
					}

					& + #nav {
						visibility: visible;
						width: 100%;
						z-index: 199;
					}
				}
			}
		}

		nav {
			align-items: center;
			display: flex;
			justify-content: flex-end;
			margin-left: auto;
			width: 100%;

			@include mq(tablet-down) {
				display: none;
			}


			.sub-search-input-wrapper {
				height: 50px;
				position: relative;
				max-width: 785px;
				width: 100%;

				&.mod-hide {
					display: none;
				}

				.input-search-bar {
					border: 0.3px solid rgba(0, 0, 0, 0.12);
					border-radius: 5px;
					filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.12));
					height: 50px;
					padding: 0 50px 0 20px;
					position: relative;
					max-width: 785px;
					width: 100%;

					&::placeholder {
						color: #686868;
						font-size: 17px;
						font-weight: $font-weight-book;
						line-height: 33px;
						margin-left: 20px;
					}
				}

				> svg.icon-cross {
					cursor: pointer;
					height: 15px;
					position: absolute;
					right: 17px;
					stroke: #638DEB;
					top: 37%;
					width: 15px;
					z-index: 8;
				}

				.btn {
					background-color: $color-background;
					border-radius: 7px;
					border: none;
					color: $color-white;
					font-size: $font-size-standard;
					height: 39px;
					justify-content: center;
					margin-top: 0;
					max-width: 87px;
					padding: 0;
					position: absolute;
					right: 50px;
					top: 6px;
					transition: opacity .3s ease;
				}
			}

			ul.nav-list {
				@extend %list-default;

				display: flex;

				li.nav-item {
					color: $color-black;
					cursor: pointer;
					display: inline-block;
					font-size: 14px;
					font-weight: $font-weight-medium;
					padding: 0 20px;

					@media screen and (max-width: 1424px) {
						padding: 0 15px;
					}

					@media screen and (max-width: 1324px) {
						padding: 0 10px;
					}

					&:last-child {
						@media screen and (max-width: 1424px) {
							padding-right: 0;
						}
					}

					> svg {
						height: 4.04px;
						fill: $color-buttons;
						margin-bottom: 1px;
						margin-left: 5px;
						vertical-align: middle;
						width: 8.08px;
					}

					& > a {
						color: $color-black;
						font-size: 14px;
						font-weight: $font-weight-medium;
					}
				}

				.sub-menu {
					@extend %list-default;
				}

				&.mod-hide {
					display: none;
				}
			}

			.nav-buttons {
				display: flex;
				height: 41px;
				justify-content: center;
				margin-left: 20px;

				@media screen and (max-width: 1324px) {
					margin-left: 20px !important;
				}

				.btn-primary {
					font-size: 14px;
					font-weight: $font-weight-book;
					justify-content: center;
					margin-left: 5px;
					width: 135px;

					&.mod-hide {
						display: none;
					}

					.icon-account {
						color: $color-white;
						height: 24px;
						margin: 0;
						margin-right: 10px;
						stroke-width: 2px;
						width: 18px;

						@include mq(tablet-down) {
							margin-right: 5px;
						}
					}
				}

				.icon-account-link {
					align-items: center;
					display: flex;
					height: 100%;
					justify-content: center;
					margin: 0 2px 0 20px;
					width: 32px;

					// .icon-account {
					// 	height: 24px;
					// 	margin: 0;
					// 	width: 17px;
					// }

				}

				.icon-search-link {
					align-items: center;
					display: flex;
					height: 100%;
					justify-content: center;
					margin-left: 15px;
					width: 32px;

					.icon-search {
						background-color: unset;
						border: none;
						height: 24px;
						width: 27px;
						stroke-width: 2px;
					}
				}

				&.mod-hide {
					display: none;
				}
			}
		}
	}
}

.section-nav {
	background-color: $color-primary;
	height: 100vh;
	left: 0;
	overflow-y: scroll;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 99999;

	&.mod-hide {
		display: none;
		height: unset;
	}

	.nav-large {
		@extend .header-main;

		border-bottom: 1px solid #1e548b;

		@include mq(tablet-down) {
			align-items: flex-start !important;
			border: none;
			display: flex !important;
			height: 100%;
			margin-top: 85px;
		}


		.sub-container {
			@extend %container;

			align-items: center;
			justify-content: flex-end;

			@include mq(tablet-down) {
				align-items: center;
				justify-content: center;
			}


			.nav-list {
				@extend .nav-list;

				align-items: center;
				position: relative;

				@include mq(tablet-down) {
					flex-direction: column;
					justify-content: center;
				}


				> li.nav-item {
					color: $color-white;

					@include mq(tablet-down) {
						font-size: 17px;
						font-weight: $font-weight-book;
						line-height: 23px;
						margin-bottom: 30px;
						text-align: center;
					}


					&.link-account {
						display: none;

						@include mq(tablet-down) {
							display: block;
							text-align: center;
						}
					}

					> a {
						color: $color-white;

						@include mq(tablet-down) {
							font-size: 17px;
							font-weight: $font-weight-book;
							line-height: 23px;
						}


						&.search-suppliers, &.join-us {
							@include mq(tablet-down) {
								align-items: center;
								background: $color-buttons;
								border-radius: $radius-button;
								color: $color-white;
								display: flex;
								font-size: 14px;
								font-weight: $font-weight-book;
								height: 41px;
								justify-content: center;
								letter-spacing: 0.5px;
								line-height: 19px;
								padding: 10px 30px;
								text-align: center;
								transition: all 0.1s ease;
								position: relative;
								overflow: hidden;
								width: 182px;
								z-index: 0;
							}
						}
					}

					> svg {
						height: 4.04px;
						fill: $color-white;
						margin-bottom: 1px;
						margin-left: 5px;
						vertical-align: middle;
						width: 8.08px;
					}

					&.active {
						svg {
							transition: all 270ms ease-out;
							transform: rotate(-180deg);
						}
					}
				}

				> .nav-buttons {
					margin-left: 50px;

					@include mq(tablet-down) {
						flex-direction: column;
						height: unset;
						margin: 0;
						margin-left: 0 !important;
					}


					> a {
						@include mq(tablet-down) {
							max-height: 48px;
							margin: 0;
						}


						&:nth-child(2) {
							@include mq(tablet-down) {
								margin: 10px 0;
							}
						}

						&:last-child {
							@include mq(tablet-down) {
								margin: 0 0 40px 0;
							}
						}
					}
				}

				> li.item-twitter-link {
					> a {
						.icon-x {
							color: $color-buttons;
							display: none;

							@include mq(tablet-down) {
								display: block;
							}
						}
					}
				}
			}

			> svg.icon-cross {
				cursor: pointer;
				height: 14px;
				margin-left: 33px;
				stroke: white;
				stroke-width: 2px;
				width: 14px;

				@include mq(tablet-down) {
					height: 18px;
					position: absolute;
					right: 25px;
					stroke: #638DEB;
					stroke-width: 1px;
					top: 25px;
					width: 18px;
				}
			}

			.sub-search-mobile {
				display: none;

				@include mq(tablet-down) {
					cursor: pointer;
					display: block;
					height: 24px;
					position: absolute;
					right: 60px;
					stroke: #638deb;
					stroke-width: 1px;
					top: 21px;
					width: 24px;

					svg {
						height: 24px;
						width: 24px;
					}
				}
			}
		}
	}

	.sub-menu {
		color: $color-white;
		position: absolute;
		top: 90px;

		@include mq(tablet-down) {
			margin-top: 10px !important;
			position: relative;
			text-align: center;
			top: unset;
		}


		&.mod-hide {
			display: none;
		}

		&.about-ssaib {
			> li:first-child {
				> a {
					font-weight: $font-weight-book;
				}
			}
		}

		&.advice-and-services {
			.sub-menu-parent {
				> a {
					font-weight: $font-weight-book;
				}
			}
		}

		> li {
			cursor: default;
			color: $color-white;
			font-size: 15px;
			font-weight: $font-weight-regular;
			letter-spacing: 0.6px;
			line-height: 35px;

			@include mq(tablet-down) {}


			> a {
				cursor: pointer;
				color: $color-white;
				font-size: 15px;
				font-weight: $font-weight-light;
				line-height: 35px;
			}
		}
	}
}

.section-search-mobile {
	background-color: $color-white;
	display: flex;
	height: 100vh;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 9999999;

	input {
		background-color: $color-white;
		border: none;
		color: $color-black;
		font-size: 30px;
		font-weight: $font-weight-book;
		line-height: 33px;
		height: 60px;
		margin: 156px auto 0 auto;
		position: relative;
		text-align: center;
		width: 80%;

		&::placeholder {
			color: $color-black;
			font-size: 30px;
			font-weight: $font-weight-book;
			line-height: 33px;
			opacity: 1;
		}

		&:focus {
			border: none;
			border-bottom: 1px solid $color-borders;
			outline: none;

			&::placeholder {
				color: transparent;
			}
		}
	}

	> svg.icon-cross {
		cursor: pointer;
		height: 18px;
		position: absolute;
		right: 25px;
		stroke: #638deb;
		stroke-width: 1px;
		top: 25px;
		width: 18px;
		z-index: 8;
	}

	&.mod-hide {
		display: none;
	}
}
