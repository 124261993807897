#news {
	.section-hero {
		align-items: center;
		background-color: $color-white;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		display: flex;
		height: 455px;
		overflow: hidden;
		position: relative;
		width: 100%;
		z-index: 8;

		.sub-gradient {
			background: linear-gradient(270deg, rgba(#FFF, 0), rgba(#FFF, 100%));
			height: 100%;
			position: absolute;
			width: 70%;

			@include mq(tablet-down) {
				width: 100%;
			}
		}

		.sub-content {
			@extend %container;

			z-index: 1;

			a {
				justify-content: center;
			}

			h1 {
				font-size: 40px;
				font-weight: $font-weight-medium;
				line-height: 50px;
				margin-bottom: 50px;
				max-width: 732px;
				width: 100%;
			}

			.btn {
				max-width: 157px;
				width: 100%;
			}
		}
	}

	.section-blog-list {
		background-color: #f7f7f7;
		padding: 65px 0 55px;
		position: relative;
		z-index: 8;

		.wrap-list {
			@extend %container;

			.sub-nav {
				display: flex;
				justify-content: center;
				margin: 0 auto 50px auto;

				.post-nav-button {
					color: $color-buttons;
					cursor: pointer;
					font-size: 17px;

					&.mod-active {
						cursor: default;
						text-decoration: underline;
					}

					&--events {
						color: $color-tertiary;
					}
				}

				.sub-divider {
					font-size: 21px;
					font-weight: 100;
					line-height: 24px;

					&::after {
						color: $color-borders-light;
						content: '|';
						margin: 0 30px;
					}
				}
			}

			.list-posts {
				@extend %list-default;

				display: flex;
				flex-wrap: wrap;

				@include mq(mobile) {
					flex-direction: column;
				}


				& > li {
					background: $color-white;
					border-radius: 25px;
					margin-bottom: 30px;
					margin-right: 20px;
					padding: 23px 20px 40px 20px;
					position: relative;
					width: calc((100% / 3) - 14px);

					@include mq(mobile) {
						width: 100%;
					}


					&:nth-child(3n) {
						margin-right: 0;
					}

					.sub-image {
						background-position: center center;
						background-repeat: no-repeat;
						background-size: cover;
						height: 254px;
						margin-bottom: 27px;
						width: 100%;
					}

					.sub-category {
						font-weight: $font-weight-regular;
						font-size: 16px;
						letter-spacing: .7px;
						line-height: 21px;
						margin-bottom: 20px;
						text-transform: uppercase;

						&.mod-news {
							color: $color-buttons;
						}

						&.mod-event {
							color: $color-tertiary;
						}
					}

					.sub-date {
						color: #949494;
						font-size: 13px;
						letter-spacing: .7px;
						line-height: 21px;
						margin-bottom: 10px;
					}

					header {
						padding-right: 15px;

						h3 {
							font-weight: $font-weight-regular;
							line-height: 35px;
						}
					}

					.sub-copy {
						font-size: 13px;
						font-weight: $font-weight-book;
						line-height: 21px;
						padding-right: 15px;
					}

					.sub-link {
						height: 100%;
						left: 0;
						position: absolute;
						top: 0;
						width: 100%;
						z-index: 8;
					}
				}

				.item-post {
					display: none;

					&.mod-show {
						display: block;
					}
				}
			}
		}
	}
}
