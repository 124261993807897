#flexible-scope {
	.section-hero {
		background: $color-background;
		flex-direction: column;
		display: flex;
		height: 371px;
		justify-content: center;
		position: relative;
		z-index: 999;

		@include mq(mobile) {
			height: 271px;
		}


		.sub-content {
			@extend %container;

			.sub-copy {
				margin-top: 20px;

				@include mq(mobile) {
					margin-top: 0;
				}


				h1 {
					color: $color-white;
					font-size: 40px;
					font-weight: $font-weight-medium;
					line-height: 54px;

					@include mq(mobile) {
						font-size: 25px;
					}
				}

				p {
					color: $color-white;
					font-size: 22px;
					font-weight: $font-weight-book;
					line-height: 36px;
					max-width: 725px;
					width: 100%;

					@include mq(mobile) {
						font-size: 15px;
						line-height: 28px;
						padding-right: 30px;
					}
				}

				.btn {
					background-color: $color-white;
					color: $color-buttons;
					justify-content: center;
					max-width: 157px;
					margin-top: 30px;
					width: 100%;
				}
			}
		}
	}

	.section-main-copy {
		background-color: $color-white;
		padding: 75px 0;
		position: relative;
		z-index: 8;

		@include mq(mobile) {
			padding: 35px 0;
		}


		.sub-content {
			@extend %container;

			display: flex;

			@include mq(mobile) {
				flex-direction: column;
			}


			.sub-copy {
				margin-right: 75px;

				@include mq(mobile) {
					margin-right: 0;
				}


				h3, h4 {
					font-size: 17px;
					font-weight: $font-weight-medium;
				}

				p {
					color: $color-black;
					font-size: 17px;
					font-weight: $font-weight-book;
					line-height: 30px;

					> a {
						color: $color-buttons;
					}

					@include mq(mobile) {
						font-size: 15px;
						line-height: 28px;
					}
				}

				strong {
					font-weight: $font-weight-regular;
				}

				ul {
					@extend %list-default;

					margin-bottom: 25px;

					li {
						color: $color-black;
						font-size: 17px;
						font-weight: $font-weight-book;
						line-height: 30px;

						@include mq(mobile) {
							font-size: 15px;
						}


						&:before {
							content: '• ';
							color: $color-buttons;
							display: inline-block;
							font-size: 22px;
							margin-right: 20px;
						}
					}
				}
			}

			.sub-image {
				background-position: center center;
				background-repeat: no-repeat;
				background-size: cover;
				border-radius: 25px;
				height: 394px;
				max-width: 436px;
				width: 100%;

				@include mq(mobile) {
					height: 320px;
					margin-top: 45px;
					max-width: none;
				}
			}
		}
	}
}
