// Define placeholders within here to be used within further sass.
%container {
	width: $container-width;
	max-width: $container-max-width;
	margin: 0 auto;

	// Example of how to extend container. Used via `@extend %container-large`
	&-large {
		@extend %container;

		max-width: $container-max-width-large;
	}

	&-small {
		@extend %container;

		max-width: $container-max-width-small;
	}

	@include mq(mobile) {
		width: $container-width-mobile;
		max-width: $container-max-width-mobile;
		margin: 0 auto;
	}
}

%clearfix {
	zoom: 1;

	&:after {
		clear: both;
	}

	&:before,
	&:after {
		content: "";
		display: table;
	}
}

%noselect {
	-webkit-touch-callout: none;
	user-select: none;
}

%nofocus {
	&:focus {
		outline-style: none;
		box-shadow: none;
	}
}

%list-default {
	margin: 0;
	padding: 0;
	list-style: none;

	& > li {
		margin: 0;
		padding: 0;
	}
}

%animation-right {
	align-items: center;
	display: flex;

	& > div {
		align-items: center;
		display: flex;
		margin-right: 10px;
		overflow: hidden;
	}

	&:hover svg {
		animation: arrow-anim-right 1s;
	}
}

%common-three-boxes {
	background-color: $color-neutral;
	overflow: hidden;
	padding-bottom: 160px;
	padding-top: 95px;
	position: relative;
	z-index: 8;

	@include mq(mobile) {
		padding: 65px 0;
	}

	.sub-content {
		@extend %container;

		h3 {
			font-size: 30px;
			font-weight: $font-weight-medium;
			line-height: 54px;
			margin-bottom: 50px;

			@include mq(mobile) {
				font-size: $font-size-h3;
				margin-bottom: 40px;
			}
		}

		ul {
			@extend %list-default;

			display: flex;
			flex-wrap: wrap;

			@include mq(mobile) {
				flex-direction: column;
			}

			& > li {
				background-color: $color-white;
				border-radius: 23px;
				display: flex;
				flex-direction: column;
				margin-bottom: 25px;
				margin-right: 35px;
				padding: 31px 31px 40px 31px;
				position: relative;
				width: calc((100% / 3) - 24px);

				@include mq(mobile) {
					margin-right: 0;
					margin-bottom: 13px;
					width: 100%;
				}

				&:nth-child(3n) {
					margin-right: 0;
				}

				& > a {
					height: 100%;
					left: 0;
					position: absolute;
					top: 0;
					width: 100%;
				}

				& > img {
					height: 45px;
					width: 45px;
				}

				& > h5 {
					font-size: 19px;
					font-weight: $font-weight-medium;
					line-height: 60px;
					margin: 0;
				}

				& > p {
					font-size: 15px;
					font-weight: $font-weight-light;
					line-height: 30px;

					@include mq(mobile) {
						font-size: $font-size-standard;
					}
				}

				& > span {
					color: $color-buttons;
					font-size: 16px;
					margin-top: auto;

					@include mq(mobile) {
						font-size: 15px;
					}

					& > svg {
						align-self: center;
						color: $color-buttons;
						height: 10.58px;
						margin-left: 15px;
						width: 15.41px;
					}
				}
			}
		}
	}
}

%image-content-block {
	overflow: hidden;
	padding: 143px 0 141px 0;
	position: relative;
	z-index: 8;

	.sub-content {
		@extend %container;

		display: flex;

		.sub-content-wrapper {
			width: 50%;

			@include mq(tablet-down) {
				margin-bottom: 40px;
				padding: 0;
				width: 100%;
			}

			h1 {
				font-size: 30px;
				font-weight: $font-weight-medium;
				line-height: 54px;

				@include mq(tablet-down) {
					font-size: 25px;
					line-height: 60px;
				}
			}

			p {
				font-size: 17px;
				font-weight: $font-weight-book;
				line-height: 30px;
				width: 100%;

				> a {
					color: $color-buttons;
				}

				@include mq(tablet-down) {
					font-size: 15px;
					line-height: 28px;
					max-width: unset;
				}

				&:first-of-type {
					font-size: 20px;
					font-weight: $font-weight-book;
					line-height: 30px;

					@include mq(tablet-down) {
						font-size: 16px;
						line-height: 28px;
					}
				}
			}
		}

		.sub-content-image {
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
			border-radius: 25px;
			display: block;
			height: 454px;
			position: relative;
			max-width: 627px;
			width: 100%;

			@include mq(tablet-down) {
				border-radius: 25px;
				height: 416px;
				max-width: unset;
				width: 100%;
			}

			@include mq(mobile) {
				height: 254px;
			}
		}
	}

	&-left {
		@include mq(tablet-down) {
			padding: unset;
		}

		.sub-content {
			@include mq(tablet-down) {
				flex-direction: column-reverse;
			}

			.sub-content-wrapper {
				padding: 0 0 0 105px;

				@include mq(mobile) {
					padding-left: 0;
					width: 100%;
				}

				p {
					max-width: 581px;
					width: 100%;
				}
			}
		}
	}

	&-right {
		@include mq(tablet-down) {
			padding: 60px 0;
		}

		.sub-content {
			justify-content: space-between;

			@include mq(tablet-down) {
				flex-direction: column;
			}

			.sub-content-wrapper {
				p {
					max-width: 638px;
				}
			}
		}
	}
}

%faqs {
	background-color: $color-white;
	border-bottom: 1px solid #eaeaea;
	overflow: hidden;
	position: relative;
	padding-bottom: 96px;
	z-index: 8;

	@include mq(tablet-down) {
		padding-bottom: 50px;
	}

	.sub-content {
		@extend %container;

		.list-frequent-questions {
			@extend %list-default;

			.item-frequent-question {
				.sub-faq-question-block {
					align-items: center;
					background-color: $color-neutral;
					border-radius: 7px;
					cursor: pointer;
					display: flex;
					flex-wrap: wrap;
					justify-content: space-between;
					margin-bottom: 5px;
					padding: 0 22px;

					@include mq(tablet-down) {
						height: unset;
						padding: 20px 15px;
					}

					h2 {
						color: $color-black;
						font-size: 19px;
						font-weight: $font-weight-medium;
						line-height: 60px;
						margin-bottom: 3px;

						@include mq(tablet-down) {
							font-size: 17.5px;
							line-height: 22px;
							width: 80%;
						}
					}

					svg {
						color: $color-buttons;
						height: 10px;
						transition: all 270ms ease-out;
						width: 14px;
					}

					&.active {
						svg {
							transition: all 270ms ease-out;
							transform: rotate(-180deg);
						}
					}

					.sub-faq-answer-block {
						background-color: $color-neutral;
						width: 100%;

						p {
							font-size: 15px;
							font-weight: $font-weight-book;
							line-height: 30px;

							> a {
								color: $color-buttons;
							}

							@include mq(tablet-down) {
								margin-top: 25px;
							}
						}
					}
				}
			}
		}
	}
}
