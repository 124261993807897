#job-page {
	.section-hero {
		background: $color-background;
		flex-direction: column;
		display: flex;
		justify-content: center;
		height: 371px;
		overflow: hidden;
		position: relative;
		z-index: 8;

		@include mq(mobile) {
			height: 271px;
		}


		.sub-content {
			@extend %container;

			.sub-copy {
				margin-top: 20px;

				@include mq(mobile) {
					margin-top: 0;
				}


				h1 {
					color: $color-white;
					font-size: 40px;
					font-weight: $font-weight-medium;
					line-height: 54px;

					@include mq(mobile) {
						font-size: 25px;
					}
				}

				.job-details-wrapper {
					display: flex;

					> h4 {
						color: $color-white;
						font-size: 22px;
						font-weight: $font-weight-book;
						line-height: 36px;
						margin-right: 25px;
					}
				}
			}

			.list-button-links {
				@extend %list-default;

				display: flex;
				margin: 20px 0 0 0;

				@include mq(tablet-down) {
					margin: 50px auto;
					width: 92%;
				}


				@include mq(mobile) {
					justify-content: center;
				}


				.item-button-link {
					margin: 0 8px 0 0;
					text-align: center;

					@include mq(tablet-down) {
						margin: 0 20px 0 0;
					}


					&:last-child {
						margin: 0;
					}

					> a {
						background-color: $color-white;
						color: $color-buttons;
						font-weight: $font-weight-book;
						margin: 0 auto;
						justify-content: center;
						width: 157px;

						@include mq(tablet-down) {
							font-size: 14px;
							max-width: unset;
						}
					}
				}
			}
		}
	}

	.section-content {
		background-color: $color-white;
		border-bottom: 1px solid #eaeaea;
		overflow: hidden;
		padding: 88px 0 65px;
		position: relative;
		z-index: 8;

		@include mq(mobile) {
			padding: 35px 0;
		}


		.sub-content {
			@extend %container;

			align-content: center;
			display: flex;

			@include mq(mobile) {
				flex-direction: column;
			}


			.sub-copy {
				flex: 1;
				margin-right: 95px;
				margin-top: 10px;

				@include mq(mobile) {
					margin-right: 0;
				}


				p {
					font-size: 17px;
					font-weight: $font-weight-book;
					line-height: 30px;

					> a {
						color: $color-buttons;
					}

					@include mq(mobile) {
						font-size: 15px;
						line-height: 28px;
					}
				}
			}
		}
	}
}
