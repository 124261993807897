.footer-main {
	background: $color-white;
	bottom: 0;
	height: 100%;
	max-height: 560px;
	opacity: 1;
	padding: 105px 0 110px;
	position: fixed;
	transition: opacity 0.3s ease;
	width: 100%;
	z-index: 1;

	@include mq(tablet-down) {
		padding: 35px 0;
		position: relative;
	}

	&.mod-hide {
		opacity: 0;
	}

	.sub-content {
		@extend %container;

		.sub-top {
			display: flex;

			@include mq(tablet-down) {
				flex-direction: column;
			}

			.sub-logo {
				flex: 1;
				margin-right: 155px;
				max-width: 97px;

				@include mq(tablet-down) {
					margin-bottom: 40px;
					margin-right: 0;
					max-width: none;
				}

				svg {
					color: $color-black;
					height: 18px;
					opacity: 0.53;
					width: 97px;

					@include mq(tablet-down) {
						margin-bottom: 30px;
						opacity: 1;
					}
				}

				.sub-lets-talk {
					display: none;

					@include mq(tablet-down) {
						color: $color-black;
						display: block;
						font-size: $font-size-largest;
						font-weight: $font-weight-medium;
						line-height: 41px;
						margin-bottom: 30px;
						width: 100%;
					}
				}
			}

			.sub-footer-links {
				@extend %list-default;

				flex: 1;
				max-width: 115px;

				@include mq(tablet-down) {
					display: none;
				}

				&:not(:last-child) {
					margin-right: auto;
				}

				&.list-convo-links {
					max-width: 150px;

					@include mq(tablet-down) {
						display: none;
					}
				}

				&.list-engage-links {
					display: none;

					@include mq(tablet-down) {
						display: block;
						margin-bottom: 70px;
					}
				}

				& > .sub-link-title {
					color: $color-black;
					font-size: 17px;
					font-weight: $font-weight-medium;
					margin-bottom: 30px;

					@include mq(tablet-down) {
						color: $color-black;
						font-size: 22px;
						margin-bottom: 25px;
					}
				}

				li {
					line-height: 23px;

					@include mq(tablet-down) {
						margin-bottom: 8px;
					}

					> i {
						display: none;

						@include mq(tablet-down) {
							display: inline-block;
							font-size: $font-size-med;
							margin-right: 18px;
						}
					}

					&.mod-desktop {
						display: block;

						@include mq(tablet-down) {
							display: none;
						}
					}

					> a {
						color: $color-black;
						display: block;
						font-size: 13px;
						font-weight: $font-weight-light;
						height: 25px;
						margin-bottom: 4px;

						@include mq(tablet-down) {
							font-size: 18px;
						}
					}
				}
			}

			.sub-contact-us {
				border-left: 1px solid #cfcfcf;
				display: flex;
				justify-content: flex-end;
				width: 433px;

				@include mq(tablet-down) {
					border-left: none;
					width: 100%;
				}

				.contact-us-wrapper {
					width: 50%;

					@include mq(tablet-down) {
						width: 100%;
					}

					.get-in-touch {
						align-items: center;
						display: flex;
						justify-content: space-between;
						margin-bottom: 25px;
						width: 90%;

						a {
							align-items: center;
							display: flex;
							justify-content: center;

							@include mq(tablet-down) {
								display: none;
							}

							svg {
								color: $color-buttons;
								width: 24px;
							}
						}
					}

					.contact-button {
						font-size: 14px;
						justify-content: center;
						line-height: 17px;
						margin: 35px auto 0 auto;
						min-width: 100%;
						padding: 8px;
						width: 100%;

						@include mq(tablet-down) {
							display: none;
						}
					}

					.contact-details {
						@extend %list-default;

						> li {
							align-items: center;
							display: flex;
							font-size: 17px;
							font-weight: $font-weight-regular;
							line-height: 33px;

							@include mq(tablet-down) {
								font-size: 19px;
								line-height: 30px;
								margin-bottom: 5px;
							}

							> p {
								color: $color-black;
								font-size: 17px;
								font-weight: $font-weight-light;
								line-height: 33px;
								margin: 0 auto;

								> a {
									color: $color-black;
								}

								@include mq(tablet-down) {
									font-size: 19px;
									line-height: 30px;
									margin: 0 0 0 15px;
								}
							}
						}
					}

					.contact-buttons-mobile {
						display: none;

						@include mq(tablet-down) {
							display: block;
						}

						.contact-wrapper {
							display: flex;
							justify-content: space-between;
							margin-bottom: 20px;

							@include mq(tablet) {
								justify-content: flex-start;
							}

							> a {
								color: $color-buttons;
								font-size: 19px;
								font-weight: $font-weight-regular;
								line-height: 33px;

								> svg {
									color: $color-buttons;

									@include mq(tablet) {
										margin-left: 20px;
									}
								}
							}
						}

						.buttons-wrapper {
							display: flex;

							a {
								font-size: 14px;
								font-weight: $font-weight-book;
								max-width: unset;

								&:not(:first-child) {
									margin-left: 10px;
								}
							}
						}
					}
				}
			}

			.sub-logos {
				margin-left: auto;

				@include mq(tablet-down) {
					margin-left: 0;
					order: 2;
				}

				.list-socials {
					@extend %list-default;

					align-items: center;
					display: flex;
					margin-bottom: 50px;

					& > li {
						&:not(:last-child) {
							margin-right: 25px;
						}
					}
				}

				.list-other-logos {
					@extend %list-default;

					align-items: center;
					display: flex;

					@include mq(tablet-down) {
						display: none;
					}

					& > li {
						&:not(:last-child) {
							margin-right: 39px;
						}
					}
				}
			}
		}

		.sub-copyright {
			display: flex;
			justify-content: space-between;
			margin-top: 95px;
			text-align: right;

			@include mq(mobile) {
				align-items: left;
				flex-direction: column;
				margin-top: 30px;
				text-align: left;
			}

			.list-address {
				@extend %list-default;

				@include mq(tablet-down) {
					align-items: flex-end;
					display: flex;
					flex-direction: column;
				}

				@include mq(mobile) {
					align-items: flex-start;
				}

				.item-address {
					color: $color-black;
					font-size: 13px;
					font-weight: $font-weight-light;
					line-height: 23px;
					text-align: right;

					@include mq(tablet-down) {
						font-size: 12px;
						max-width: 85%;
					}

					@include mq(mobile) {
						text-align: left;
						font-size: 14px;
					}

					&--title {
						font-weight: $font-weight-regular;
					}
				}
			}

			.footer-bottom-left {
				align-items: center;
				display: flex;

				@include mq(mobile) {
					flex-wrap: wrap;
				}

				img {
					margin-right: 44px;

					@include mq(tablet-down) {
						margin-right: 15px;
						transform: scale(0.75);
					}

					@include mq(mobile) {
						margin-bottom: 25px;
					}
				}

				.bafe-logo {
					width: 87px;
				}

				.npcc-logo {
					width: 105px;
				}

				.cyber-logo {
					width: 68px;
				}

				.ukas-logo {
					width: 126px;
				}

				.sia-logo {
					width: 134px;
				}
			}

			.footer-bottom-right {
				flex-direction: column;
			}

			.list-info-pages {
				@extend %list-default;

				display: flex;
				margin-bottom: 25px;
				position: relative;

				@include mq(tablet-down) {
					flex-wrap: wrap;
					justify-content: flex-end;
				}

				@include mq(mobile) {
					justify-content: flex-start;
				}

				& > li.item-info-page {
					margin-left: 50px;
					position: relative;

					@include mq(tablet-down) {
						margin-left: 20px;
					}

					@include mq(mobile) {
						margin-right: 30px;
					}

					&--copyright {
						color: $color-black;
						font-size: 13px;
						font-weight: $font-weight-medium;
						line-height: 23px;

						@include mq(tablet) {
							margin-left: 0;
						}

						@include mq(tablet-down) {
							font-weight: $font-weight-medium;
							margin-right: 0;
							width: 100%;
						}

						@include mq(mobile) {
							font-size: 12px;
							margin: 10px 0 5px 0;
						}
					}

					a {
						color: $color-black;
						font-size: 13px;
						font-weight: $font-weight-medium;
						line-height: 23px;

						@include mq(tablet-down) {
							font-weight: $font-weight-regular;
							letter-spacing: 0.2px;
						}

						@include mq(mobile) {
							font-size: 12px;
						}
					}

					&--privacy {
						color: $color-black;
						font-size: 13px;
						font-weight: $font-weight-medium;
						line-height: 23px;
						cursor: pointer;

						@include mq(mobile) {
							font-weight: $font-weight-regular;
						}

						> svg {
							height: 4.42px;
							fill: #686868;
							margin: 0 0 0 5px;
							width: 8.84px;
							-webkit-transition: transform 0.3s ease-in-out;
						}

						&.mod-active {
							> svg {
								transform: rotate(180deg);
								-webkit-transition: transform 0.3s ease-in-out;

								@include mq(mobile) {
									transform: none;
								}
							}
						}
					}
				}

				.list-privacy-pages {
					@extend %list-default;

					background-color: $color-white;
					display: none;
					filter: drop-shadow(0 2px 15px rgba(0, 0, 0, 0.13));
					padding: 15px 23px 17px 23px;
					position: absolute;
					right: 0;
					opacity: 0;
					top: -185px;

					&:after {
						content: "";
						position: absolute;
						right: 5px;
						margin: 0 auto;
						width: 0;
						height: 0;
						border-top: 25px solid white;
						border-left: 25px solid transparent;
						border-right: 25px solid transparent;
						bottom: -14px;

						@include mq(mobile) {
							display: none;
						}
					}

					@include mq(mobile) {
						filter: none;
						left: unset;
						padding: 10px 0;
						position: relative;
						top: unset;
						right: unset;
						width: 100%;
					}

					&.mod-active {
						display: block;
						opacity: 1;
						transition: opacity 0.3s linear;
					}

					.item-privacy-page {
						margin-bottom: 10px;
						text-align: left;

						&:last-of-type {
							margin-bottom: 0;
						}

						> a {
							color: $color-black;
							font-size: 13px;
							font-weight: $font-weight-book;
							line-height: 23px;
						}
					}
				}
			}
		}
	}

	.sub-back-to-top {
		display: none;
	}
}
