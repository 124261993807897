#contact {
	.section-form {
		background-color: $color-white;
		display: flex;
		padding: 80px 0 100px 0;
		position: relative;
		z-index: 8;

		@include mq(tablet-down) {
			padding: 45px 0 10px 0;
		}


		.sub-container {
			@extend %container;

			display: flex;

			@include mq(tablet-down) {
				flex-direction: column;
			}


			form {
				@extend %container;

				max-width: 644px;

				@include mq(tablet-down) {
					max-width: none;
					margin-bottom: 40px;
					margin-top: 50px;
				}


				.sub-errors {
					color: $color-error;
					display: none;
					margin-bottom: 20px;

					.sub-error {
						font-size: $font-size-med;
					}

					span {
						display: block;
						margin-bottom: 3px;
					}
				}

				h2 {
					font-size: 23px;
					font-weight: $font-weight-medium;
					line-height: 31px;
					margin-bottom: 25px;

					@include mq(tablet-down) {
						font-size: 25px;
						line-height: 60px;
						margin-bottom: 5px;
					}
				}

				p {
					font-size: 16px;
					font-weight: $font-weight-book;
					line-height: 25px;
					margin-bottom: 40px;
					width: 100%;

					@include mq(tablet-down) {
						font-size: 15px;
						line-height: 28px;
						margin-bottom: 25px;
					}
				}

				.form-element {
					&--checkbox {
						justify-content: flex-start;
						margin: 25px 0 0 0;
						padding-left: 113px;

						@include mq(mobile) {
							flex-direction: row;
							padding-left: 0;
						}


						> input {
							border: 1px solid rgba(#C3C3C3, 0.8);
							height: 25px;
							max-width: unset;
							width: 27px;

							@include mq(mobile) {
								height: 22px;
								margin-right: 20px;
								width: 22px;
							}
						}

						> span {
							margin-left: 25px;
							max-width: unset;

							@include mq(mobile) {
								margin: 0;
							}
						}
					}
				}

				button {
					align-items: center;
					font-weight: $font-weight-book;
					height: 50px;
					justify-content: center;
					margin-top: 50px;
					max-width: 157px;
					padding-left: 0;
					padding-right: 0;
					text-align: center;

					@include mq(tablet-down) {
						margin: 50px auto 0 auto;
					}
				}
			}

			.aside-contact-us {
				max-width: 454px;
				width: 100%;

				a.btn-primary {
					height: 50px;
					justify-content: center;
					margin-top: 50px;
					max-width: 208px;

					@include mq(tablet-down) {
						font-size: 14px;
						font-weight: $font-weight-book;
						line-height: 19px;
						margin-top: 40px;
					}
				}

				h1 {
					font-size: 40px;
					font-weight: $font-weight-medium;
					line-height: 54px;

					@include mq(tablet-down) {
						font-size: 25px;
						line-height: 60px;
					}
				}

				p {
					color: $color-black;
					font-size: 18px;
					font-weight: $font-weight-book;
					line-height: 29px;
					max-width: 434px;
					width: 100%;

					> a {
						color: $color-black;
					}

					@include mq(tablet-down) {
						font-size: 15px;
						line-height: 28px;
					}
				}

				a {
					color: $color-black;
				}

				ul.contact-details {
					@extend %list-default;

					margin-top: 55px;

					@include mq(tablet-down) {
						margin-top: 25px;
					}


					li {
						display: flex;
						font-size: 22px;
						font-weight: $font-weight-regular;
						line-height: 48px;

						@include mq(tablet-down) {
							font-size: 19px;
							line-height: 32px;
						}


						a.email-address {
							color: $color-buttons;
							font-weight: $font-weight-book;
							margin: 0 0 0 10px;
						}

						p {
							color: $color-black;
							font-size: 22px;
							font-weight: $font-weight-regular;
							line-height: 48px;
							margin: 0 0 0 10px;

							@include mq(tablet-down) {
								font-size: 19px;
								line-height: 32px;
							}
						}
					}
				}
			}

			.wrap-thanks {
				font-size: $font-size-larger;
				display: none;
			}
		}
	}

	.section-map {
		position: relative;
		z-index: 8;

		.sub-map-header {
			align-items: center;
			background-color: $color-neutral;
			display: flex;
			height: 94px;
			justify-content: center;

			@include mq(tablet-down) {
				flex-direction: column;
				height: unset;
				padding: 20px 15px;
			}


			h3 {
				color: #003e7e;
				font-size: 22px;
				font-weight: $font-weight-medium;
				line-height: 29px;
				margin-right: 40px;
				margin-bottom: 0;

				@include mq(tablet-down) {
					font-size: 18px;
					margin: 0 0 5px 0;
				}
			}

			h4 {
				font-size: 22px;
				font-weight: $font-weight-book;
				line-height: 29px;
				margin-bottom: 0;

				@include mq(tablet-down) {
					font-size: 15px;
					line-height: 22px;
					max-width: 50%;
				}
			}
		}

		#map {
			height: 500px;

			@include mq(tablet-down) {
				height: 528px;
			}
		}
	}
}
