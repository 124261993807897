#maintaining-quality {
	.section-hero {
		background: $color-background;
		display: flex;
		flex-direction: column;
		height: 432px;
		justify-content: center;
		position: relative;
		overflow: hidden;
		z-index: 8;

		@include mq(mobile) {
			height: unset;
			padding: 30px 0;
		}

		.sub-content {
			@extend %container;

			.sub-hero-content {
				margin-top: 20px;
				max-width: 900px;
				width: 100%;

				@include mq(mobile) {
					margin-top: 0;
				}

				h1 {
					color: $color-white;
					font-size: 40px;
					font-weight: $font-weight-medium;
					line-height: 54px;

					@include mq(mobile) {
						font-size: 25px;
					}
				}

				p {
					color: $color-white;
					font-size: 22px;
					font-weight: $font-weight-book;
					line-height: 36px;
					max-width: 725px;
					width: 100%;

					@include mq(mobile) {
						font-size: 15px;
						line-height: 28px;
					}
				}
			}
		}
	}

	.section-faqs {
		@extend %faqs;

		padding: 60px 0;

		.sub-content {
			article {
				margin-bottom: 80px;

				& > h2 {
					font-weight: $font-weight-medium;
					margin-bottom: 45px;
				}

				.faq-intro {
					margin-bottom: 50px;

					p {
						font-size: 20 / $font-base * 1rem;
						font-weight: $font-weight-book;
						line-height: 33px;
					}
				}
			}
		}
	}
}
