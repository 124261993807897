#news-post {
	.section-hero {
		background-color: $color-white;
		overflow: hidden;
		position: relative;
		padding: 55px 0 20px 0;
		z-index: 8;

		@include mq(tablet-down) {
			padding: 35px 0 0 0;
		}


		.wrap-hero {
			@extend %container;

			display: flex;
			flex-direction: row;
			justify-content: space-between;
			position: relative;

			@include mq(tablet-down) {
				flex-wrap: wrap;
			}


			.sub-copy {
				max-width: 615px;
				padding-top: 80px;

				@include mq(tablet-down) {
					padding-top: 20px;
					width: 100%;
				}


				h1 {
					font-size: 35px;
					font-weight: $font-weight-medium;
					line-height: 49px;
					margin: 0 0 20px;

					@include mq(tablet-down) {
						font-size: $font-size-h2;
						line-height: 40px;
					}
				}

				h3 {
					color: #3BC597;
					font-size: 14px;
					font-weight: $font-weight-medium;
					line-height: 25px;
					margin: 0 0 40px;

					@include mq(tablet-down) {
						display: none;
					}
				}

				p {
					font-size: 15px;
					font-weight: $font-weight-book;
					line-height: 25px;
					margin: 0;
				}

				.sub-social {
					position: relative;
					margin: 50px 0 0 0;

					@include mq(tablet-down) {
						display: none;
					}


					ul.list-social {
						@extend %list-default;

						width: 25px;
						overflow: hidden;
						transition: all 0.3s ease;
						display: flex;

						@include mq(tablet-down) {
							padding: 20px 0 0;
						}


						&:hover,
						&:focus {
							width: 100%;
						}

						li {
							margin: 0 22px 0 0;
							padding: 0;
							display: inline-block;

							.svg-icon {
								cursor: pointer;
								transition: color 0.2s ease;
								color: #3BC597;

								&:hover {
									color: rgba(#3BC597, 0.3);
								}
							}
						}
					}
				}
			}

			.sub-image {
				height: 413px;
				width: 666px;
				margin: 0 0 0 auto;

				@include mq(tablet-down) {
					height: unset;
					margin: 45px 0;
					width: 100%;
				}


				ul.list-image {
					@extend %list-default;

					@include mq(tablet-down) {
						height: 208px;
					}


					li.item-image {
						margin: 0;

						@include mq(tablet-down) {
							height: 208px;
						}


						.sub-background {
							background-position: center;
							background-size: cover;
							height: 413px;

							@include mq(tablet-down) {
								height: 208px;
							}
						}
					}
				}
			}
		}
	}

	.section-post {
		background-color: $color-white;
		overflow: hidden;
		position: relative;
		padding: 0 0 70px;
		z-index: 8;

		@include mq(tablet-down) {
			padding: 10px 0 60px;
		}


		.sub-content {
			@extend %container, %clearfix;

			.sub-copy {
				max-width: 48%;

				@include mq(tablet-down) {
					max-width: 100%;
				}


				p {
					font-size: 15px;
					font-weight: $font-weight-book;
					line-height: 25px;

					a {
						color: $color-buttons;
					}
				}
			}
		}
	}

	.section-related.post-page {
		background-color: #F9F9F9;
		overflow: hidden;
		position: relative;
		padding: 60px 0 115px 0;
		z-index: 8;

		@include mq(tablet-down) {
			padding: 60px 0 80px;
		}


		.wrap-related {
			@extend %container;

			h2 {
				margin: 0 0 55px;
				font-size: 30px;
				font-weight: $font-weight-medium;

				@include mq(tablet-down) {
					margin: 0 0 40px;
				}
			}

			.list-recent-posts {
				@extend %list-default;

				display: flex;

				@include mq(tablet-down) {
					flex-direction: column;
				}


				.item-recent-post {
					background-color: $color-white;
					border-radius: 25px;
					margin: 0 20px;
					padding: 23.23px 21.82px 53.54px 21.82px;
					width: calc(100% / 3);

					&:first-child, &:last-child {
						margin: 0;
					}

					@include mq(tablet-down) {
						margin-bottom: 20px;
						width: 100%;
					}


					.sub-post-image-bg {
						background-position: center;
						background-repeat: no-repeat;
						background-size: cover;
						height: 254px;
					}

					.sub-post-details {
						margin-top: 20px;

						> span {
							color: #3BC597;
							display: block;
							font-size: 14px;
							line-height: 23px;
							margin-bottom: 20px;
						}

						> h2 {
							font-size: 22px;
							font-weight: $font-weight-book;
							line-height: 31px;
							margin-bottom: 30px;
						}

						> p {
							font-size: 14px;
							line-height: 23px;
						}
					}
				}
			}
		}
	}
}
