@keyframes input-fade {
	50% {
		opacity: 0;
		transform: translate3d(1em, 0, 0);
	}

	51% {
		opacity: 0;
		transform: translate3d(-1em, -110%, 0);
	}

	100% {
		color: rgba($color-text, 0.55);
		font-size: $font-size-standard;
		font-weight: $font-weight-light;
		opacity: 1;
		transform: translate3d(0, -100%, 0);
	}
}

@keyframes arrow-anim-right {
	from {
		transform: translateX(0%);
	}

	40% {
		transform: translateX(150%);
	}

	50% {
		opacity: 0;
		transform: translateX(-90%);
	}

	70% {
		opacity: 1;
		transform: translateX(0%);
	}
}