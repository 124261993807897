form {
	.sub-errors p {
		margin: 0 0 5px;
		font-size: $font-size-small;
		color: $color-validation-error;
	}

	.form-element {
		align-items: center;
		display: flex;
		justify-content: space-between;
		margin: 0 0 10px;
		overflow: hidden;
		position: relative;
		vertical-align: top;
		width: 100%;
		z-index: 1;

		@include mq(tablet-down) {
			align-items: flex-start;
			flex-direction: column;
		}

		&.error {
			label {
				color: $color-validation-error;
			}
		}

		input {
			height: 44px;
		}

		textarea {
			height: 112px;
		}

		input, textarea {
			border: 1px solid rgba(#C3C3C3, 0.56);
			color: $color-text;
			display: block;
			position: relative;
			font-size: 15px;
			max-width: 531px;
			width: 100%;

			@include autocomplete-fill {
				background-color: $color-white;
				box-shadow: 0 0 0px 1000px $color-white inset;
				-webkit-text-fill-color: $color-text;
				transition: background-color 5000s ease-in-out 0s;
			}

			@include mq(tablet-down) {
				font-size: $font-size-med;
				padding: 22px 20px;
			}

			&:focus {
				border-color: $color-primary;

				+ label {
					color: $color-primary;
					font-size: $font-size-large;
					font-weight: $font-weight-semibold;
				}

				@include mq(mobile) {
					@include placeholder {
						color: $color-primary;
					}
				}
			}

			@include placeholder {
				color: $color-white;
			}
		}

		label {
			color: #505050;
			font-size: 16px;
			font-weight: $font-weight-light;
			max-width: 113px;
			position: relative;
			padding: 0;
			pointer-events: none;
			text-align: left;
			width: 100%;

			@include mq(tablet-down) {
				font-size: 14px;
				line-height: 19px;
				margin-bottom: 10px;
			}

			span {
				display: block;

				@include mq(tablet-down) {
					margin-top: 3px;
				}
			}
		}

		textarea {
			padding: 22px 0 4px;
		}
	}

	.btn.btn-primary {
		margin: 25px 0 0;
		padding: 13px 70px;

		@include mq(mobile) {
			padding: 15px 0;
			width: 100%;
			margin: 55px 0 0;
		}
	}
}

input, select, textarea, button, optgroup {
	font-family: $font-standard !important;
}

input[name="Form_Email"] {
	display: none;
	opacity: 0;
	visibility: hidden;
	height: 0;
}
