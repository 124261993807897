#protecting-your-subpage {
	.section-hero {
		background: $color-background;
		display: flex;
		flex-direction: column;
		height: 432px;
		justify-content: center;
		position: relative;
		overflow: hidden;
		z-index: 8;

		@include mq(mobile) {
			height: unset;
			padding: 30px 0;
		}


		.sub-content {
			@extend %container;

			.sub-copy {
				margin-top: 20px;

				@include mq(mobile) {
					margin-top: 0;
				}


				h1 {
					color: $color-white;
					font-size: 40px;
					font-weight: $font-weight-medium;
					line-height: 54px;

					@include mq(mobile) {
						font-size: 25px;
					}
				}

				p {
					color: $color-white;
					font-size: 22px;
					font-weight: $font-weight-book;
					line-height: 36px;
					max-width: 725px;
					width: 100%;

					@include mq(mobile) {
						font-size: 15px;
						line-height: 28px;
					}
				}
			}
		}
	}

	.section-content {
		background-color: $color-white;
		padding: 88px 0 105px;
		position: relative;
		z-index: 8;

		@include mq(mobile) {
			padding: 35px 0;
		}


		.sub-content {
			@extend %container;

			align-content: center;
			display: flex;

			@include mq(mobile) {
				flex-direction: column;
			}


			.sub-copy {
				flex: 1;
				margin-right: 95px;
				margin-top: 10px;

				@include mq(mobile) {
					margin-right: 0;
				}


				sub-content-intro {
					p {
						font-size: 20px;
						font-weight: $font-weight-book;
						line-height: 30px;
					}
				}

				> h2 {
					font-size: 30px;
					font-weight: $font-weight-medium;
					line-height: 54px;
					margin-bottom: 40px;

					@include mq(mobile) {
						font-size: $font-size-h3;
						margin-bottom: 10px;
					}
				}

				p {
					font-size: 17px;
					font-weight: $font-weight-book;
					line-height: 21px;

					> a {
						color: $color-buttons;
					}

					@include mq(mobile) {
						font-size: 15px;
						line-height: 28px;
					}
				}

				ul {
					@extend %list-default;

					margin-bottom: 25px;

					li {
						color: $color-black;
						font-size: 17px;
						font-weight: $font-weight-book;
						line-height: 21px;

						@include mq(mobile) {
							font-size: 15px;
						}
					}
				}
			}

			.sub-image {
				background-position: center center;
				background-repeat: no-repeat;
				background-size: cover;
				border-radius: 25px;
				height: 384px;
				max-width: 436px;
				width: 100%;

				@include mq(mobile) {
					height: 320px;
					margin-top: 45px;
					max-width: none;
				}
			}
		}
	}

	.section-more-on {
		background-color: $color-white;
		overflow: hidden;
		position: relative;
		padding-bottom: 96px;
		z-index: 8;

		@include mq(tablet-down) {
			padding-bottom: 50px;
		}


		.sub-content {
			@extend %container;

			> h2 {
				font-size: 30px;
				font-weight: $font-weight-medium;
				line-height: 54px;
				margin-bottom: 40px;

				@include mq(mobile) {
					font-size: $font-size-h3;
					margin-bottom: 10px;
				}
			}

			.list-more-on {
				@extend %list-default;

				.item-more-on {
					cursor: pointer;

					.sub-more-on-wrapper {
						align-items: center;
						background-color: $color-neutral;
						border-radius: 7px;
						cursor: pointer;
						display: flex;
						flex-wrap: wrap;
						justify-content: space-between;
						margin-bottom: 5px;
						padding: 0 22px;

						@include mq(tablet-down) {
							height: unset;
							padding: 20px 15px 17px 15px;
						}


						h2 {
							color: $color-black;
							font-size: 19px;
							font-weight: $font-weight-medium;
							line-height: 60px;
							margin-bottom: 3px;

							@include mq(tablet-down) {
								font-size: 17.5px;
								line-height: 22px;
								width: 80%;
							}
						}

						svg {
							color: $color-buttons;
							height: 10px;
							transition: all 270ms ease-out;
							width: 14px;
						}

						&.active {
							svg {
								transition: all 270ms ease-out;
								transform: rotate(-180deg);
							}
						}

						.sub-more-on-answer-block {
							background-color: $color-neutral;
							display: none;
							padding-bottom: 30px;
							max-width: 100%;

							&.mod-show {
								display: block;
							}

							p {
								font-size: 15px;
								font-weight: $font-weight-book;
								line-height: 30px;
								margin-bottom: 0;

								> a {
									color: $color-buttons;
								}

								> strong {
									font-weight: $font-weight-regular;
								}

								@include mq(tablet-down) {
									margin-top: 25px;
								}
							}

							li {
								font-weight: $font-weight-book;
								line-height: 23px;
								list-style-type: square;
								margin-bottom: 15px;
							}
						}
					}
				}
			}
		}
	}

	.section-content-block-2 {
		@extend .section-content;

		padding-top: 0;
		border-bottom: 1px solid #eaeaea;
		overflow: hidden;
		padding: 0 0 130px 0;
		position: relative;
		z-index: 8;

		@include mq(tablet-down) {
			padding-bottom: 60px;
		}


		.sub-content {
			@extend %container;

			.sub-copy {
				margin-top: 0;

				@include mq(tablet-down) {
					max-width: 95%;
				}


				p {
					font-size: 15px;
					font-weight: $font-weight-book;
					line-height: 30px;
					margin-bottom: 0;

					> strong {
						font-weight: $font-weight-regular;
					}

					@include mq(tablet-down) {
						margin-top: 25px;
					}
				}

				ul {
					margin-left: 0;
					list-style-type: square;

					li {
						font-size: 15px;
						font-weight: $font-weight-book;
						line-height: 30px;
						margin-bottom: 20px;
						padding-left: 10px;

						@include mq(tablet-down) {
							margin-bottom: 15px;
						}


						&::marker {
							color: $color-buttons;
						}
					}
				}
			}

			.sub-image {
				align-items: flex-end;
				display: flex;
				overflow: hidden;

				.sub-link-wrapper {
					align-items: center;
					background-color: $color-tertiary;
					display: flex;
					height: 83px;
					padding: 0 20px;
					width: 100%;

					a {
						font-size: 16px;
						font-weight: $font-weight-regular;

						> svg {
							color: $color-white;
							display: inline-block;
							height: 9.86px;
							margin-left: 15px;
							width: 14px;
						}
					}
				}
			}
		}
	}
}
